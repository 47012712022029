import React, { useMemo } from 'react';

import cn from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

import './index.scss';

import Button from '~/components/shared/buttons/Button';
import ThrottledButton from '~/components/shared/buttons/ThrottledButton';
import Loader from '~/components/shared/Loader';
import Bloc from '~/components/shared/shaping/Bloc';
import Filler from '~/components/shared/shaping/Filler';
import type { DevicePlatform, EnrollmentLink, EnrollmentPackageType } from '~/graphql/schema';
import DevicePlatformIconV2 from '../../display/devices/DevicePlatformIcon';

type EnrollmentItem = {
  url?: string | null;
  platform: DevicePlatform;
  type: EnrollmentPackageType;
  buttonState: 'building' | 'request' | 'ready';
};

type EnrollmentCardListProps = {
  items: EnrollmentLink[];
  packageRequestedAt: Date | null;
  averageBuildTime: number | undefined;
  loading?: boolean;
  onRefreshLinksEvent: () => void;
  mode?: 'download' | 'copy';
  excludedPackagesType?: EnrollmentPackageType[];
};

const computeButtonState = (url: string | null | undefined, isPastBuildTime: boolean) => {
  if (url) {
    return 'ready';
  }

  return isPastBuildTime ? 'request' : 'building';
};

// Should be deleted to use EnrollmentCardListV2 everywhere (without v2) in PRI-4364
const EnrollmentCardList: FunctionComponent<EnrollmentCardListProps> = ({
  className,
  items,
  packageRequestedAt,
  loading,
  averageBuildTime,
  mode = 'download',
  onRefreshLinksEvent,
  excludedPackagesType = [],
}) => {
  const { t } = useTranslation();
  const packageShouldBeReadyAt = packageRequestedAt
    ? moment(packageRequestedAt).utc().add(averageBuildTime, 'minutes').toDate()
    : null;
  const isPastBuildTime = useMemo(() => moment().utc().isSameOrAfter(packageShouldBeReadyAt), [packageShouldBeReadyAt]);

  const handleLinkReady = (link: string | null | undefined) => {
    if (!link) return;

    if (mode === 'download') {
      window.open(link);
      return;
    }

    if (mode === 'copy') {
      navigator.clipboard.writeText(link);
      toast.success(<span>{t('success.link_copied')}</span>);
    }
  };

  const enrollmentItem = useMemo(
    () =>
      items.reduce<EnrollmentItem[]>((acc, item) => {
        item.urls
          .filter(p => !excludedPackagesType.includes(p.type))
          .forEach(({ url, type }) => {
            acc.push({
              url,
              platform: item.platform,
              type,
              buttonState: computeButtonState(url, isPastBuildTime),
            });
          });
        return acc;
      }, []),
    [items, isPastBuildTime, excludedPackagesType],
  );

  return !enrollmentItem.length ? (
    <Loader spacing="sm" />
  ) : (
    <Bloc className={cn('pri-enrollment-card-list', className)}>
      <Flex className="pri-enrollement-card-list-items" direction="column" fullWidth>
        {enrollmentItem.map(item => (
          <Flex key={item.type} className="pri-enrollment-card-row" direction="row" gap={4} align="center" fullWidth>
            <DevicePlatformIconV2 className="pri-device-icon-rounded" platform={item.platform} width={24} />
            <Text weight="medium">
              {t(
                `titles.enrollment_stepper.download_installer.${item.platform.toLowerCase()}${`_${item.type.toLowerCase()}`}`.toString(),
              )}
            </Text>
            <Filler />
            {loading && <Loader spacing="sm" />}
            {item.buttonState === 'building' && !loading && (
              <ThrottledButton
                variant="dark-outline"
                timerStartedAt={packageRequestedAt ?? undefined}
                onFinish={() => {
                  onRefreshLinksEvent();
                }}
                timer={moment(packageShouldBeReadyAt).utc().diff(moment.utc().toDate(), 'seconds')}
                text={t('buttons.enrollment_stepper.building')}
              />
            )}
            {item.buttonState === 'request' && !loading && (
              <Button
                loading={loading}
                variant="dark-outline"
                onClick={() => {
                  onRefreshLinksEvent();
                }}
              >
                {t('buttons.enrollment_stepper.request')}
              </Button>
            )}
            {item.buttonState === 'ready' && !loading && (
              <Button
                loading={loading}
                variant="dark-outline"
                onClick={() => {
                  handleLinkReady(item.url);
                }}
              >
                {t(`buttons.enrollment_stepper.${mode}`)}
              </Button>
            )}
          </Flex>
        ))}
      </Flex>
    </Bloc>
  );
};

export default EnrollmentCardList;
