import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const QuestionIcon: SvgComponent = ({ className, width = 16, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M8.16484 12.9995C8.68951 12.9995 9.11484 12.5742 9.11484 12.0496C9.11484 11.5249 8.68951 11.0996 8.16484 11.0996C7.64017 11.0996 7.21484 11.5249 7.21484 12.0496C7.21484 12.5742 7.64017 12.9995 8.16484 12.9995Z"
      fill={color}
    />
    <path
      d="M8.8 9.83312H7.53333V7.29987H8.8C9.13594 7.29987 9.45812 7.16642 9.69567 6.92888C9.93321 6.69135 10.0667 6.36918 10.0667 6.03325C10.0667 5.69732 9.93321 5.37515 9.69567 5.13761C9.45812 4.90007 9.13594 4.76662 8.8 4.76662H7.53333C7.19751 4.76701 6.87555 4.90058 6.63809 5.13803C6.40063 5.37549 6.26705 5.69744 6.26667 6.03325V6.3499H5V6.03325C5.00075 5.36162 5.2679 4.71772 5.74283 4.2428C6.21776 3.76789 6.86168 3.50075 7.53333 3.5H8.8C9.47188 3.5 10.1162 3.76689 10.5913 4.24197C11.0664 4.71705 11.3333 5.36139 11.3333 6.03325C11.3333 6.7051 11.0664 7.34945 10.5913 7.82452C10.1162 8.2996 9.47188 8.56649 8.8 8.56649V9.83312Z"
      fill={color}
    />
  </svg>
);

export default QuestionIcon;
