import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const MilestoneIcon: SvgComponent = ({ className, width = 16, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2928 3.29295C12.2002 3.19978 12.09 3.12591 11.9686 3.07562C11.8472 3.02533 11.7171 2.99963 11.5857 3H8V1H7V3H3C2.73489 3.00033 2.48072 3.10579 2.29326 3.29326C2.10579 3.48072 2.00033 3.73488 2 4V7C2.00033 7.26511 2.10579 7.51928 2.29326 7.70674C2.48072 7.89421 2.73489 7.99967 3 8H7V15H8V8H11.5857C11.7171 8.00037 11.8472 7.97467 11.9686 7.92438C12.09 7.87409 12.2002 7.80022 12.2928 7.70705L14.5 5.5L12.2928 3.29295ZM11.5857 7H3V4H11.5858L13.0858 5.5L11.5857 7Z"
      fill={color}
    />
  </svg>
);

export default MilestoneIcon;
