import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const MouseIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4375 7.50004C8.4375 7.8107 8.68934 8.06254 9 8.06254C9.31066 8.06254 9.5625 7.8107 9.5625 7.50004V5.25004C9.5625 4.93938 9.31066 4.68754 9 4.68754C8.68934 4.68754 8.4375 4.93938 8.4375 5.25004V7.50004Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0625 6.80578C14.0625 4.31466 12.2502 2.19376 9.78956 1.80524C9.26643 1.72264 8.73357 1.72264 8.21044 1.80524C5.7498 2.19376 3.9375 4.31466 3.9375 6.80579V11.1943C3.9375 13.6854 5.7498 15.8063 8.21044 16.1948C8.73357 16.2774 9.26643 16.2774 9.78956 16.1948C12.2502 15.8063 14.0625 13.6854 14.0625 11.1943L14.0625 6.80578ZM9.6141 2.91647C11.5279 3.21865 12.9375 4.86825 12.9375 6.80578L12.9375 11.1943C12.9375 13.1318 11.5279 14.7814 9.6141 15.0836C9.20722 15.1478 8.79278 15.1478 8.3859 15.0836C6.47207 14.7814 5.0625 13.1318 5.0625 11.1943L5.0625 6.80579C5.0625 4.86825 6.47207 3.21865 8.3859 2.91647C8.79278 2.85222 9.20722 2.85222 9.6141 2.91647Z"
      fill={color}
    />
  </svg>
);

export default MouseIcon;
