import React, { useContext } from 'react';
import { Recap, StepperContext, Steps } from '~/components/shared/Stepper';
import { useCompany } from '~/hooks/useCompany';

import EmployeeRecap from './_recaps/EmployeeRecap';
import EquipmentsRecap from './_recaps/EquipmentsRecap';
import PriceRecap from './_recaps/PriceRecap';
import TitleRecap from './_recaps/Title';
import ChooseAppsStep from './_steps/ChooseAppsStep';
import ChooseEmployeeStep from './_steps/ChooseEmployeeStep';
import ChooseEquipmentsStep from './_steps/ChooseEquipmentsStep';
import ChooseLicensesStep from './_steps/ChooseLicensesStep';
import ChooseShippingAndBillingStep from './_steps/ChooseShippingAndBillingStep';
import FillEmployeeInformationStep from './_steps/FillEmployeeInformationStep';
import { OnboardingStepperContext } from './context';

const OnboardingStepperContent: FunctionComponent<{ employeeId?: string | null }> = ({ employeeId }) => {
  const { contextData } = useContext(OnboardingStepperContext);
  const { currentStep } = useContext(StepperContext);
  const company = useCompany();

  // When employeeId is set, we skip the first step (choose employee)
  const isFillEmployeeInformationStep = employeeId ? 1 : 2;
  const isEquipmentStep = employeeId ? 2 : 3;

  const shouldDisplayEquipmentsRecap =
    currentStep > isEquipmentStep &&
    contextData.equipments &&
    (contextData.equipments.catalog.length > 0 || contextData.equipments?.inventory.length > 0);

  if (!company) {
    return null;
  }

  const displayBilling = contextData.equipments?.catalog.length;
  const displayLicensing = contextData.companyExternalIntegrations?.find(
    cei => cei.externalIntegration?.hasLicensing || cei.requireBackupEmail,
  );

  return (
    <>
      <Steps>
        {employeeId ? undefined : <ChooseEmployeeStep />}
        <FillEmployeeInformationStep />
        <ChooseEquipmentsStep />
        {displayBilling ? <ChooseShippingAndBillingStep /> : undefined}
        <ChooseAppsStep companyId={company.id} />
        {displayLicensing ? <ChooseLicensesStep /> : undefined}
      </Steps>
      {currentStep > isFillEmployeeInformationStep && (
        <Recap>
          <TitleRecap />
          <EmployeeRecap employeeId={employeeId} />
          {shouldDisplayEquipmentsRecap && (
            <>
              <EquipmentsRecap employeeId={employeeId} />
              <PriceRecap />
            </>
          )}
        </Recap>
      )}
    </>
  );
};

export default OnboardingStepperContent;
