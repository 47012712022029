import type { MouseEvent } from 'react';
import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import IntegrationCard from '~/components/featured/cards/IntegrationCard';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Step, StepperContext } from '~/components/shared/Stepper';
import { Text, Title } from '~/components/shared/typography';

import type { OffboardingStepperContextProps } from '../context';
import { OffboardingStepperContext } from '../context';

const ChooseAppsStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const { contextData, setContextData, companyExternalIntegrations, companyExternalIntegrationsLoading } =
    useContext(OffboardingStepperContext);
  const { setFooterProps } = useContext(StepperContext);
  const form = useForm({
    defaultValues: { apps: contextData.apps },
  });

  const apps = form.watch('apps');

  const onNext = useCallback(async () => {
    const newContextData = { ...contextData, apps };
    setContextData(newContextData);
    return true;
  }, [contextData, apps, setContextData]);

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel: t('buttons.next'),
    });
  }, [setFooterProps, onNext, t]);

  const handleOnClick = useCallback(
    (e: MouseEvent<HTMLDivElement>, integration: OffboardingStepperContextProps['companyExternalIntegrations'][0]) => {
      e.preventDefault();
      if (!integration.externalIntegration) {
        return;
      }
      const appsSet = new Set(apps || []);
      const { name } = integration.externalIntegration;

      if (appsSet.has(name)) {
        appsSet.delete(name);
      } else {
        appsSet.add(name);
      }
      form.setValue('apps', Array.from(appsSet));
    },
    [apps, form],
  );

  if (!companyExternalIntegrationsLoading && companyExternalIntegrations.length === 0) {
    return null;
  }

  return (
    <Step size="lg">
      <Flex className="pri-mb-9" direction="column" gap={2}>
        <Title level={2}>{t('steppers.offboarding.remove_access_from_apps')}</Title>
        <Text weight="light">{t('steppers.offboarding.remove_access_from_apps_description')}</Text>
      </Flex>
      {companyExternalIntegrationsLoading && <Loader />}
      <Flex fullWidth direction="row" wrap="wrap" gap={4}>
        {companyExternalIntegrations
          .filter(integration =>
            contextData.employee?.provisioning?.some(p => p.name === integration.externalIntegration?.name),
          )
          .map(integration =>
            integration.externalIntegration ? (
              <IntegrationCard
                key={integration.externalIntegration.name}
                selected={(apps || []).includes(integration.externalIntegration.name)}
                checkbox={{
                  name: 'apps',
                  value: integration,
                  register: form.register,
                }}
                companyExternalIntegration={integration}
                onClick={e => handleOnClick(e, integration)}
              />
            ) : null,
          )}
      </Flex>
    </Step>
  );
};

export default ChooseAppsStep;
