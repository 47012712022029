import { useCallback, useEffect, useMemo } from 'react';
import { ok, refuse } from '@primo/operation-result';
import type { CreateOrderMutation, OrderCreationInput, ProductInOrderInput } from '~/graphql/schema';
import { useCreateOrderMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseCreateOrderVariables = {
  data: OrderCreationInput;
  companyId: string;
  productsInOrder: Array<ProductInOrderInput> | ProductInOrderInput;
};

const useMutationCreateOrder = (queryOptions: UseQueryOptions<UseCreateOrderVariables> = {}) => {
  const serializeOffboardingData = useCallback((data: CreateOrderMutation | undefined | null) => {
    if (data?.createOrder?.__typename === 'MutationCreateOrderSuccess') {
      return ok(data.createOrder.data);
    }
    if (data?.createOrder?.__typename === 'MutationCreateOrderError') return refuse(data.createOrder.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [mutation, { data: createOrderData, loading: createOrderLoading, called: createOrderCalled }] =
    useCreateOrderMutation({
      refetchQueries: ['ListOrdersPage', 'GetEmployeePage', 'GetCompanyBillingProfiles', 'GetCockpitDataQuery'],
    });

  const createOrderResult = useMemo(
    () => serializeOffboardingData(createOrderData),
    [serializeOffboardingData, createOrderData],
  );

  const createOrder = useCallback(
    async (variables: UseCreateOrderVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeOffboardingData(result.data);
    },
    [mutation, serializeOffboardingData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !createOrderCalled) createOrder({ ...queryOptions });
  }, [createOrder, queryOptions, createOrderCalled]);

  return useMemo(
    () => ({
      createOrder,
      createOrderResult,
      createOrderLoading,
      createOrderCalled,
    }),
    [createOrder, createOrderResult, createOrderLoading, createOrderCalled],
  );
};

export default useMutationCreateOrder;
