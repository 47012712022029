import cn from 'classnames';
import { debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Select from '~/components/shared/forms/Select';
import Bloc from '~/components/shared/shaping/Bloc';
import Flex from '~/components/shared/shaping/Flex';
import { useResolver } from '~/hooks/queries/useResolver';

type PartialEmployee = { fullname?: string | null; id?: string | null };

type EmployeeSelectProps<T extends FieldValues> = {
  className?: string;
  name: Path<T>;
  form: UseFormReturn<T>;
  companyId: string;
  onEmployeesLoad?: (employees: PartialEmployee[]) => void;
  showEmptyOption?: boolean;
  showNotArrived?: boolean;
  defaultValue?: PartialEmployee | null;
  errorMessage?: string | undefined;
  label?: string | null;
};

const EmployeeSelect = <T extends FieldValues>({
  label,
  companyId,
  showEmptyOption,
  errorMessage,
  onEmployeesLoad,
  className,
  showNotArrived = false,
  ...props
}: EmployeeSelectProps<T>) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);
  const [search, setSearch] = useState('');
  const {
    loading,
    result: employeesResult,
    executeResolver: getViewEmployees,
  } = useResolver('useGetViewEmployeesLazyQuery');

  const options = useMemo(() => {
    const employees = employeesResult?.getViewEmployees?.data ?? [];
    onEmployeesLoad?.(employees);
    const employeesOptions = new Map<string, PartialEmployee>(employees.map(employee => [employee.id, employee]));

    return showEmptyOption ? [{ id: '', fullname: '-' }, ...employeesOptions.values()] : [...employeesOptions.values()];
  }, [employeesResult, onEmployeesLoad, showEmptyOption]);

  useEffect(() => {
    getViewEmployees({ filters: { terms: search }, companyId });
  }, [companyId, search, getViewEmployees, showNotArrived]);

  const debouncedSetSearch = useMemo(() => debounce(setSearch, 300), [setSearch]);

  return (
    <Flex direction="column" className={className}>
      <Select
        label={label || t('forms.labels.employee')}
        options={options}
        getOptionLabel={option => option.fullname || '-'}
        getOptionValue={option => option?.id || ''}
        onInputChange={debouncedSetSearch}
        isLoading={loading}
        menuIsOpen={opened}
        onFocus={() => !opened && setOpened(true)}
        onBlur={() => opened && setOpened(false)}
        {...props}
      />
      <Bloc className={cn({ 'invalid-feedback': errorMessage })}>{errorMessage && t(errorMessage)}</Bloc>
    </Flex>
  );
};

export default EmployeeSelect;
