import { t } from 'i18next';
import React from 'react';
import Button from '~/components/shared/buttons/Button';

import { type Company, type Employee } from '~/graphql/schema';
import useSessionSwitch from '~/hooks/PrimoContexts/useSessionSwitch';

type AdminSessionSwitcherType = {
  company: Pick<Company, 'id' | 'canonicalName'>;
  loggedEmployee: Pick<Employee, 'id' | 'isAdmin' | 'companyId' | 'isOnlyEmployee'>;
};

const SessionSwitcher: FunctionComponent<AdminSessionSwitcherType> = ({ company, loggedEmployee }) => {
  const { switchSession, currentSession } = useSessionSwitch(company, loggedEmployee);

  if (loggedEmployee.isOnlyEmployee || !loggedEmployee.isAdmin) return null;

  return (
    <Button className="pri-px-3" variant="light-outline" size="md" onClick={() => switchSession()}>
      {t(`buttons.session_switch_to_${currentSession?.toLowerCase()}`)}
    </Button>
  );
};

export default SessionSwitcher;
