import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const WindowsIcon2024: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.6536 12.29H4.34961V19.594H11.6536V12.29Z" fill={color} />
    <path d="M11.6536 4.35039H4.34961V11.6543H11.6536V4.35039Z" fill={color} />
    <path d="M19.5999 4.35039H12.2959V11.6543H19.5999V4.35039Z" fill={color} />
    <path d="M19.5989 12.2957H12.2949V19.5997H19.5989V12.2957Z" fill={color} />
  </svg>
);

export default WindowsIcon2024;
