import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ShoppingIcon } from '~/components/icons';
import InfoIcon from '~/components/icons/info';
import Card from '~/components/shared/Card';
import CardItem from '~/components/shared/CardItem';
import CardItemProperty from '~/components/shared/CardItem/property';
import Price from '~/components/shared/Price';
import Flex from '~/components/shared/shaping/Flex';
import Tooltip from '~/components/shared/Tooltip';
import { Text } from '~/components/shared/typography';
import { FR_VAT } from '~/utils/orders';

import { OnboardingStepperContext } from '../context';

const PriceRecap: FunctionComponent = () => {
  const { t } = useTranslation();
  const {
    contextData: { equipments },
  } = useContext(OnboardingStepperContext);

  const total = useMemo(
    () => (equipments?.catalog || []).reduce((acc, { item, quantity }) => acc + item.priceNoVAT * quantity, 0),
    [equipments?.catalog],
  );

  if (!total) {
    return null;
  }

  return (
    <Card icon={<ShoppingIcon />} title={t('steppers.orders.price_details')}>
      {(equipments?.catalog || []).map(({ item, quantity }) => (
        <CardItem key={item.id}>
          <CardItemProperty
            label={`${item.name}${quantity > 1 ? ` x ${quantity}` : ''}`}
            value={
              <Text weight="light">
                <Price value={item.priceNoVAT * quantity} />
              </Text>
            }
          />
        </CardItem>
      ))}
      <CardItem>
        <CardItemProperty
          label={t('steppers.onboarding.shipping')}
          value={
            <Flex justify="end" align="center" gap={2}>
              <Text weight="regular">{t('steppers.onboarding.free')}</Text>
              <Tooltip label={t('steppers.orders.additional_fees')} align="topRight">
                <InfoIcon width={20} />
              </Tooltip>
            </Flex>
          }
        />
      </CardItem>
      <CardItem>
        <CardItemProperty
          label={t('drawers.order.price.no_vat')}
          value={
            <Text weight="light">
              <Price value={total} />
            </Text>
          }
        />
      </CardItem>
      <CardItem>
        <CardItemProperty
          label={t('drawers.order.price.total')}
          value={
            <Text weight="medium">
              <Price value={total} vat={FR_VAT} />
            </Text>
          }
        />
      </CardItem>
    </Card>
  );
};

export default PriceRecap;
