import { useEffect, useState } from 'react';
import type { RefObject } from 'react';

export const useScrollHeight = (collapsableRef: RefObject<HTMLDivElement>) => {
  const [scrollHeight, setScrollHeight] = useState<number | undefined>();

  useEffect(() => {
    // We are using observer since cards can be hidden (like Card in hidden Tab)
    // Observers are used to update the scrollHeight when the card is shown
    const observer = new ResizeObserver(() => {
      // We add 1px to the scrollHeight to make it work if dynamic content
      // example: multiple options for applications in MdmSettingsApplicationsForm resize the card and the scrollHeight is not updated without this
      setScrollHeight(collapsableRef?.current?.scrollHeight ? collapsableRef.current.scrollHeight + 1 : undefined);
    });

    if (collapsableRef.current) {
      observer.observe(collapsableRef.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [collapsableRef]);

  return scrollHeight;
};
