import { zodResolver } from '@hookform/resolvers/zod';
import { isRefusal } from '@primo/operation-result';
import classNames from 'classnames';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { ChevronLastIcon, WarningIcon } from '~/components/icons';
import CardTile from '~/components/shared/CardTile';
import FormatDate from '~/components/shared/formatDate/Date';
import DatePicker from '~/components/shared/forms/DatePicker';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Text, Title } from '~/components/shared/typography';
import useQueryCheckExistingPersonalEmail from '~/hooks/queries/employees/useQueryCheckExistingPersonalEmail';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

import Radio from '~/components/shared/forms/Radio';
import Select from '~/components/shared/forms/Select';
import Message from '~/components/shared/Message';
import Tile from '~/components/shared/Tile';
import { OffboardingStepperContext } from '../../context';
import type { OffboardingStepperContextData } from '../../types';
import type { Fields } from './schema';
import { schema } from './schema';

const TIME_OPTIONS = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

const FillOffboardingInformationStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const { company } = useContext(PrimoAdminContext);
  const { contextData, onFinish, loading, setupCalled } = useContext(OffboardingStepperContext);
  const { setFooterProps } = useContext(StepperContext);
  const { employee, devices, apps } = contextData;
  const procedureDateFallback = useMemo(() => moment.utc().startOf('day').toISOString(), []);

  const hasProcedureDate = useMemo(() => !!devices?.length || !!apps?.length, [apps, devices]);

  const form = useForm<Fields>({
    defaultValues: {
      procedureTime: '09:00',
      procedureDate: hasProcedureDate ? employee?.terminationDate || procedureDateFallback : procedureDateFallback,
      phone: employee?.phone || undefined,
      personalEmail: employee?.personalEmail || undefined,
    },
    resolver: zodResolver(schema(hasProcedureDate, t)),
  });

  const {
    register,
    control,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = form;
  const scheduling = form.watch('scheduling');
  const procedureDate = form.watch('procedureDate');
  const procedureTime = form.watch('procedureTime');

  const isOffboardingBeforeProcedureDate = useMemo(() => {
    if (!procedureDate || !employee?.terminationDate) {
      return false;
    }
    return moment(employee.terminationDate).isBefore(procedureDate);
  }, [employee, procedureDate]);

  const { checkExistingPersonalEmail } = useQueryCheckExistingPersonalEmail();

  const onNext = useCallback(async () => {
    const isValid = await trigger();
    const values = getValues();

    if (!isValid || !company || !contextData.employee) {
      return false;
    }

    if (values.personalEmail && values.personalEmail !== employee?.personalEmail) {
      const personalEmailExists = await checkExistingPersonalEmail({
        personalEmail: values.personalEmail,
        companyId: company.id,
      });
      if (isRefusal(personalEmailExists) || personalEmailExists.data) {
        toast.error(<>{t('errors.personal_email_already_exists')}</>);
        return false;
      }
    }

    const newContextData: OffboardingStepperContextData = {
      ...contextData,
      employee: {
        ...contextData.employee,
        phone: values.phone,
        personalEmail: values.personalEmail,
      },
      procedureDate: values.procedureDate,
      procedureTime: values.procedureTime,
      scheduling: values.scheduling,
    };

    await onFinish(newContextData);
    return false;
  }, [onFinish, trigger, getValues, contextData, company, employee, t, checkExistingPersonalEmail]);

  useEffect(() => {
    if (setupCalled && !loading) {
      setValue('procedureDate', employee?.terminationDate || procedureDateFallback);
      setValue('phone', employee?.phone || undefined);
      setValue('personalEmail', employee?.personalEmail || undefined);
    }
  }, [setupCalled, loading, employee, procedureDateFallback, setValue]);

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel: t('buttons.confirm_offboarding'),
    });
  }, [setFooterProps, onNext, contextData.employee?.devices, t]);

  const timeOptions = useMemo(() => {
    if (scheduling !== 'SCHEDULED') {
      setValue('procedureTime', '09:00');
      return TIME_OPTIONS;
    }

    if (!procedureDate || moment.utc(procedureDate).isAfter(moment.utc().endOf('day'))) return TIME_OPTIONS;
    const options = TIME_OPTIONS.filter(time => {
      const [hours, minutes] = time.split(':').map(Number);
      const date = moment(procedureDate).hours(hours).minutes(minutes);
      return date.isAfter(moment());
    });
    if (!options.includes(procedureTime)) setValue('procedureTime', undefined);
    return options;
  }, [procedureDate, procedureTime, setValue, scheduling]);

  return (
    <Step size="lg">
      <Title className="pri-mb-9" level={2}>
        {t('steppers.offboarding.plan_employee_offboarding', { firstName: employee?.firstName ?? '' })}
      </Title>
      <Form form={form}>
        {loading ? (
          <Loader spacing="md" />
        ) : (
          <Flex direction="column" gap={10}>
            <fieldset>
              <legend className="pri-mb-4">
                <Flex direction="column" gap={2}>
                  <Text size="lg" weight="medium">
                    {t('steppers.offboarding.backup_contact_information')}
                  </Text>
                  <Text weight="light">{t('steppers.offboarding.backup_contact_information_usage')}</Text>
                </Flex>
              </legend>
              <Flex direction="column">
                <Flex gap={6}>
                  <Input
                    name="personalEmail"
                    label={t('forms.labels.personal_email')}
                    register={register}
                    errors={errors}
                  />
                  <Input name="phone" label={t('forms.labels.personal_phone')} register={register} errors={errors} />
                </Flex>
              </Flex>
            </fieldset>
            {!hasProcedureDate ? null : (
              <fieldset>
                <legend className="pri-mb-4">
                  <Flex direction="column" gap={2}>
                    <Text size="lg" weight="medium">
                      {t('steppers.offboarding.schedule_device_actions')}
                    </Text>
                  </Flex>
                </legend>
                {employee?.terminationDate ? (
                  <CardTile
                    className="pri-mb-7"
                    prefixIcon={<ChevronLastIcon width={16} />}
                    title={t('steppers.offboarding.employee_last_day')}
                    suffixNode={
                      <Text weight="light">
                        <FormatDate date={employee.terminationDate} format="human_date" />
                      </Text>
                    }
                  />
                ) : null}
                <Flex direction="column" gap={6}>
                  <Tile
                    fluid
                    className="pri-p-4"
                    selected={scheduling === 'NOW'}
                    onClick={
                      scheduling === 'NOW'
                        ? undefined
                        : () => {
                            form.setValue('scheduling', 'NOW');
                          }
                    }
                  >
                    <Flex justify="between" align="center" gap={2}>
                      <Text>{t('steppers.offboarding.execute_action_immediately')}</Text>
                      <Radio className="pri-flex-0" control={control} name="scheduling" options={[{ value: 'NOW' }]} />
                    </Flex>
                  </Tile>
                  <Tile
                    fluid
                    className={classNames('pri-p-4')}
                    selected={scheduling === 'SCHEDULED'}
                    onClick={
                      scheduling === 'SCHEDULED'
                        ? undefined
                        : () => {
                            form.setValue('scheduling', 'SCHEDULED');
                          }
                    }
                  >
                    <Flex direction="column" gap={2}>
                      <Flex justify="between" align="center" gap={2}>
                        <Text>{t('steppers.offboarding.schedule_for_later')}</Text>
                        <Radio
                          className="pri-flex-0"
                          control={control}
                          name="scheduling"
                          options={[{ value: 'SCHEDULED' }]}
                        />
                      </Flex>
                      {scheduling === 'SCHEDULED' && (
                        <Flex direction="column" gap={4}>
                          <Flex className="pri-mb--4" gap={4}>
                            <DatePicker
                              required
                              className="pri-flex-1"
                              name="procedureDate"
                              placeholder={t('forms.placeholders.pick_a_date')}
                              label={t('forms.labels.procedure_date')}
                              control={control}
                              errors={errors}
                              minDate={moment.utc().startOf('day').toISOString()}
                              dateFormat="DD/MM/YYYY"
                            />
                            <Select
                              name="procedureTime"
                              className="pri-flex-1"
                              label={t('forms.labels.procedure_time')}
                              options={timeOptions}
                              form={form}
                            />
                          </Flex>
                          {isOffboardingBeforeProcedureDate && (
                            <Message variant="plain-info">
                              <Flex className="pri-mb-2" gap={2}>
                                <WarningIcon width={14} />
                                <Title level={6}>
                                  {t('steppers.offboarding.scheduled_action_does_not_match_termination_date')}
                                </Title>
                              </Flex>
                              <Text size="sm" weight="light">
                                {t('steppers.offboarding.scheduled_action_does_not_match_termination_date_message', {
                                  company: company?.name,
                                })}
                              </Text>
                            </Message>
                          )}
                        </Flex>
                      )}
                    </Flex>
                  </Tile>
                </Flex>
                {errors?.scheduling?.message && (
                  <Text variant="danger" className="pri-mt-2">
                    {errors.scheduling.message.toString()}
                  </Text>
                )}
              </fieldset>
            )}
          </Flex>
        )}
      </Form>
    </Step>
  );
};

export default FillOffboardingInformationStep;
