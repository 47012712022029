import cn from 'classnames';
import React from 'react';
import { Text } from '../../typography/Text';

import './index.scss';

export type LabelProps = {
  required?: boolean;
  children: string;
};

const Label: FunctionComponent<LabelProps> = ({ className, children, required }) => (
  <Text className={cn('pri-label', { 'pri-label-required': required }, className)} weight="light">
    {children}
    {required}
  </Text>
);

export default Label;
