import React, { useContext } from 'react';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';

const BetaEmployee: FunctionComponent<{ span?: true; not?: true }> = ({
  children,
  not = false,
  span = false,
  className,
}) => {
  const Cmp = span ? 'span' : 'div';
  const { isBetaActivated } = useContext(PrimoEmployeeContext);

  if (isBetaActivated === not) {
    return null;
  }
  return <Cmp className={className}>{children}</Cmp>;
};

export default BetaEmployee;
