import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const DevicesIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 30H4C3.46957 30 2.96086 29.7893 2.58579 29.4142C2.21071 29.0391 2 28.5304 2 28V16C2 15.4696 2.21071 14.9609 2.58579 14.5858C2.96086 14.2107 3.46957 14 4 14H10C10.5304 14 11.0391 14.2107 11.4142 14.5858C11.7893 14.9609 12 15.4696 12 16V28C12 28.5304 11.7893 29.0391 11.4142 29.4142C11.0391 29.7893 10.5304 30 10 30ZM4 16V28H10V16H4Z"
      fill={color}
    />
    <path
      d="M28 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V12H6V6H28V20H14V22H16V26H14V28H23V26H18V22H28C28.5304 22 29.0391 21.7893 29.4142 21.4142C29.7893 21.0391 30 20.5304 30 20V6C30 5.46957 29.7893 4.96086 29.4142 4.58579C29.0391 4.21071 28.5304 4 28 4Z"
      fill={color}
    />
  </svg>
);

export default DevicesIcon;
