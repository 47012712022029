import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Flex from '~/components/shared/shaping/Flex';
import { Step, StepperContext } from '~/components/shared/Stepper';
import { Title } from '~/components/shared/typography';

import * as theme from '~/assets/scss/theme.module.scss';
import { Form } from '~/components/shared/forms/Form';
import Bloc from '~/components/shared/shaping/Bloc';
import { useResolver } from '~/hooks/queries/useResolver';
import { useCompany } from '~/hooks/useCompany';
import { OnboardingStepperContext } from '../../context';
import BackupEmail from './BackupEmail';
import Licensing from './Licensing';
import type { ChooseLicenseFormData } from './types';

const ChooseLicensesStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const company = useCompany();
  const { contextData, setContextData, onFinish } = useContext(OnboardingStepperContext);
  const { setFooterProps } = useContext(StepperContext);
  const { executeResolver: checkExistingPersonalEmail, loading: existingPersonalEmailLoading } = useResolver(
    'useCheckIfEmployeePersonalEmailExistLazyQuery',
  );

  const totalPrice = useMemo(
    () => contextData.equipments?.catalog.reduce((acc, { item, quantity }) => acc + item.priceNoVAT * quantity, 0) || 0,
    [contextData.equipments?.catalog],
  );

  const displayBackupEmail = contextData.companyExternalIntegrations?.some(cei => cei.requireBackupEmail);
  const displayLicensing = contextData.companyExternalIntegrations?.some(cei => cei.externalIntegration?.hasLicensing);
  const displaySeparator = displayBackupEmail && displayLicensing;

  const form = useForm<ChooseLicenseFormData>({
    defaultValues: {
      backupEmail: contextData.employee?.personalEmail,
      companyExternalIntegrationLicenses: contextData.companyExternalIntegrations?.reduce(
        (acc, cei) => {
          acc[cei.id] = [];
          return acc;
        },
        {} as { [key: string]: string[] },
      ),
    },
  });
  const { watch } = form;
  const backupEmail = watch('backupEmail');
  const companyExternalIntegrationLicenses = watch('companyExternalIntegrationLicenses');

  const onNext = useCallback(async () => {
    const formResult = form.trigger();
    if (!formResult || (displayBackupEmail && !backupEmail)) return false;

    const { result } = await checkExistingPersonalEmail({
      personalEmail: backupEmail,
      companyId: company?.id || '',
    });

    if (result?.doesEmployeePersonalEmailExist) {
      toast.error(<>{t('errors.backup_email_already_exists')}</>);
    }

    const newContextData = {
      ...contextData,
      employee: {
        ...contextData.employee,
        personalEmail: backupEmail,
      },
      companyExternalIntegrationLicenses,
    };

    setContextData(newContextData);
    onFinish(newContextData);

    return true;
  }, [
    form,
    displayBackupEmail,
    backupEmail,
    checkExistingPersonalEmail,
    company?.id,
    contextData,
    companyExternalIntegrationLicenses,
    setContextData,
    onFinish,
    t,
  ]);

  useEffect(() => {
    setFooterProps({
      loaderInButton: existingPersonalEmailLoading,
      onNext,
      nextLabel:
        totalPrice > 0 ? t('steppers.onboarding.confirm_and_place_order') : t('steppers.onboarding.confirm_onboarding'),
    });
  }, [totalPrice, setFooterProps, onNext, t, existingPersonalEmailLoading]);

  return (
    <Step size="lg">
      <Flex className="pri-mb-9" direction="column" gap={2}>
        <Title level={2}>
          {t('steppers.onboarding.finish_configuring_your_apps', { firstName: contextData.employee?.firstName ?? '' })}
        </Title>
      </Flex>
      <Form form={form}>
        <Flex fullWidth direction="row" wrap="wrap" gap={8}>
          {displayBackupEmail && <BackupEmail form={form} />}
          {displaySeparator && <Bloc style={{ height: 1, width: '100%', backgroundColor: theme.colorCream100 }} />}
          {displayLicensing && <Licensing form={form} />}
        </Flex>
      </Form>
    </Step>
  );
};

export default ChooseLicensesStep;
