import { useCallback, useContext, useEffect } from 'react';
import { useEvent, useLocalStorage } from 'react-use';
import { useTokenValidationMutation } from '~/graphql/schema';
import { ApolloAppContext } from '~/providers/api/graphql/ApolloAppProvider';
import { RestAppContext } from '~/providers/api/rest/RestAppProvider';

/** Handle Appollo & Rest context by managing access token */
const usePrimoAccessToken = (
  logout: (to?: string) => void,
  setLoading: (loading: boolean) => void,
  setConnectionError: (error: string) => void,
  removeConnectionError: () => void,
) => {
  const { hasAuthenticatedClient, graphQlErrors, setToken } = useContext(ApolloAppContext);
  const { setToken: setRestAppToken } = useContext(RestAppContext);

  const [accessToken, setAccessToken, removeAccessToken] = useLocalStorage<string>('accessToken');

  /** Check token validity : mutation is used to avoid to manage query cache */
  const [validateToken] = useTokenValidationMutation({
    // onError: not handled, the server is down or request is canceled, the user should not be logout here
    onCompleted: data => {
      if (!data?.validateToken) {
        setConnectionError('errors.login.session_expired');
        logout(window.location.pathname);
      }
    },
  });

  /** Set token to the appolo provider to get an authenticated client */
  useEffect(() => {
    if (accessToken) {
      removeConnectionError();
      setToken(accessToken);
      setRestAppToken(accessToken);
    } else {
      setLoading(false);
    }
  }, [accessToken, setToken, setRestAppToken, setLoading, removeConnectionError]);

  /** Use by event listeners to check token validity */
  const validateTokenHandler = useCallback(() => {
    if (hasAuthenticatedClient) {
      validateToken();
    }
  }, [hasAuthenticatedClient, validateToken]);

  /** Add event listeners to trigger the token validation */
  useEvent('focus', validateTokenHandler);
  useEvent('online', validateTokenHandler);

  /** If graphql errors occurred it triggers the token validation */
  useEffect(() => {
    if (graphQlErrors?.length) {
      validateTokenHandler();
    }
  }, [graphQlErrors, validateTokenHandler, logout, setConnectionError]);

  return {
    accessToken,
    hasAuthenticatedClient,
    graphQlErrors,
    setAccessToken,
    removeAccessToken,
    removeConnectionError,
  };
};

export default usePrimoAccessToken;
