import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const PlugIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22 8H21V2H19V8H13V2H11V8H10C9.46957 8 8.96086 8.21071 8.58579 8.58579C8.21071 8.96086 8 9.46957 8 10V16C8.00316 17.947 8.71558 19.8261 10.004 21.2858C11.2924 22.7455 13.0685 23.6858 15 23.9307V30H17V23.9307C18.9315 23.6858 20.7076 22.7455 21.996 21.2858C23.2844 19.8261 23.9968 17.947 24 16V10C24 9.46957 23.7893 8.96086 23.4142 8.58579C23.0391 8.21071 22.5304 8 22 8ZM22 16C22 17.5913 21.3679 19.1174 20.2426 20.2426C19.1174 21.3679 17.5913 22 16 22C14.4087 22 12.8826 21.3679 11.7574 20.2426C10.6321 19.1174 10 17.5913 10 16V10H22V16Z"
      fill={color}
    />
  </svg>
);

export default PlugIcon;
