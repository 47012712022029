import React from 'react';

import type { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Flex from '~/components/shared/shaping/Flex';

import Input from '~/components/shared/forms/Input';
import type { ChooseLicenseFormData } from './types';

const BackupEmail: FunctionComponent<{ form: UseFormReturn<ChooseLicenseFormData> }> = ({ form }) => {
  const {
    register,
    formState: { errors },
  } = form;
  const { t } = useTranslation();

  return (
    <Flex direction="column" gap={4} fullWidth>
      <Input
        label={t('forms.labels.backup_email')}
        name="backupEmail"
        register={register}
        errors={errors}
        required
        helperText={t('texts.need_backup_email')}
        className="pri-mb-4"
      />
    </Flex>
  );
};

export default BackupEmail;
