import { useCallback } from 'react';
import { useLocalStorage } from 'react-use';
import type { Company, Employee } from '~/graphql/schema';
import { EmployeeRoles } from '~/graphql/schema';

const useSessionSwitcher = (
  company: Pick<Company, 'canonicalName'> | null,
  loggedEmployee: Pick<Employee, 'id' | 'isAdmin' | 'companyId' | 'isOnlyEmployee'> | null,
) => {
  const [currentSession, setCurrentSession] = useLocalStorage<EmployeeRoles>('currentSession');

  const switchSession = useCallback(() => {
    if (!loggedEmployee?.isAdmin || !company?.canonicalName || !currentSession) {
      return;
    }

    if (currentSession === EmployeeRoles.CompanyAdmin) {
      window.location.href = `/${company.canonicalName}/employee`;
    } else if (currentSession === EmployeeRoles.CompanyEmployee) {
      window.location.href = `/dashboard`;
    }
  }, [loggedEmployee, currentSession, company]);

  return { switchSession, currentSession, setCurrentSession };
};

export default useSessionSwitcher;
