import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const SpeakerIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.16 8.07996L25.63 9.36996C27.2019 11.2319 28.0398 13.6037 27.9864 16.0398C27.933 18.4759 26.9919 20.8087 25.34 22.6L26.81 24C28.7937 21.8504 29.924 19.0504 29.9884 16.1262C30.0529 13.2019 29.0471 10.3548 27.16 8.11996V8.07996Z"
      fill={color}
    />
    <path
      d="M21.58 11.9999C22.5228 13.118 23.0249 14.5419 22.9917 16.004C22.9586 17.4661 22.3925 18.8658 21.4 19.9399L22.87 21.2999C24.1929 19.8663 24.9464 17.9987 24.9887 16.0485C25.0311 14.0982 24.3594 12.1997 23.1 10.7099L21.58 11.9999ZM18 29.9999C17.8677 29.9994 17.7368 29.9726 17.6149 29.9211C17.4931 29.8696 17.3826 29.7944 17.29 29.6999L9.67 21.9999H3C2.73478 21.9999 2.48043 21.8946 2.29289 21.7071C2.10536 21.5195 2 21.2652 2 20.9999V10.9999C2 10.7347 2.10536 10.4804 2.29289 10.2928C2.48043 10.1053 2.73478 9.99995 3 9.99995H9.67L17.29 2.29995C17.4774 2.1137 17.7308 2.00916 17.995 2.00916C18.2592 2.00916 18.5126 2.1137 18.7 2.29995C18.8884 2.48458 18.9962 2.73618 19 2.99995V28.9999C19 29.2652 18.8946 29.5195 18.7071 29.7071C18.5196 29.8946 18.2652 29.9999 18 29.9999ZM4 19.9999H10.08C10.2123 20.0005 10.3432 20.0273 10.4651 20.0788C10.5869 20.1303 10.6974 20.2055 10.79 20.2999L17 26.5699V5.42995L10.79 11.6999C10.6974 11.7944 10.5869 11.8696 10.4651 11.9211C10.3432 11.9726 10.2123 11.9994 10.08 11.9999H4V19.9999Z"
      fill={color}
    />
  </svg>
);

export default SpeakerIcon;
