/* eslint-disable jsx-a11y/anchor-is-valid */
import cn from 'classnames';
import RCTooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import type { ReactElement } from 'react';
import React from 'react';

import './index.scss';

export type TooltipProps = {
  enabled?: boolean;
  label?: ReactElement | ReactElement[] | string;
  align?:
    | 'top'
    | 'left'
    | 'right'
    | 'bottom'
    | 'topLeft'
    | 'topRight'
    | 'bottomLeft'
    | 'bottomRight'
    | 'leftTop'
    | 'leftBottom'
    | 'rightTop'
    | 'rightBottom';
};

const Tooltip: FunctionComponent<TooltipProps> = ({ children, className, align = 'bottom', enabled = true, label }) =>
  enabled ? (
    <span>
      <RCTooltip placement={align} overlay={label} overlayClassName={cn('pri-tooltip', className)}>
        <button className="pri-tooltip-trigger-button" type="button">
          {children}
        </button>
      </RCTooltip>
    </span>
  ) : (
    (children as JSX.Element)
  );

export default Tooltip;
