import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const MonitorIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V22C2 22.5304 2.21071 23.0391 2.58579 23.4142C2.96086 23.7893 3.46957 24 4 24H12V28H8V30H24V28H20V24H28C28.5304 24 29.0391 23.7893 29.4142 23.4142C29.7893 23.0391 30 22.5304 30 22V6C30 5.46957 29.7893 4.96086 29.4142 4.58579C29.0391 4.21071 28.5304 4 28 4ZM18 28H14V24H18V28ZM28 22H4V6H28V22Z"
      fill={color}
    />
  </svg>
);

export default MonitorIcon;
