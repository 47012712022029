import cn from 'classnames';
import React, { useCallback, useMemo, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import type { CSSObjectWithLabel } from 'react-select';
import ReactSelect from 'react-select';

import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Flex from '~/components/shared/shaping/Flex';
import { Text } from '~/components/shared/typography';

import type { Option, SelectProps, Value } from './types';

import { ChevronBottomIcon } from '~/components/icons';
import Label from '../Label';
import ClearIndicatorComponent from './_components/ClearIndicatorComponent';
import OptionComponent from './_components/OptionComponent';
import SelectedOptionsPills from './_components/SelectedOptionsPills';

import './index.scss';
import { useHandleOnKeyDown } from './useHandleOnKeyDown';

const Select = <FV extends FieldValues, Val extends Value, IsMulti extends boolean = false>(
  props: SelectProps<FV, Val, IsMulti>,
) => {
  const {
    label,
    name,
    options,
    form,
    className,
    helper,
    disabled,
    dark,
    onChange,
    getOptionLabel,
    getOptionJsxLabel,
    getOptionValue,
    isOptionDisabled,
    allowNewValues,
    maxOptions,
    ...reactSelectProps
  } = props;
  const {
    control,
    formState: { errors },
  } = form;
  const error = errors[name];
  const { required, menuPlacement = 'auto', isMulti, placeholder = 'Select' } = reactSelectProps;
  const [customValues, setCustomValues] = useState<Option<Val>[]>([]);
  const { t } = useTranslation();

  const handleOnKeyDown = useHandleOnKeyDown(customValues, setCustomValues, allowNewValues);
  const computedOptions = useMemo(
    () =>
      options
        .map(
          opt =>
            ({
              jsxLabel: getOptionJsxLabel?.(opt),
              label: getOptionLabel?.(opt) || (typeof opt === 'string' ? opt : JSON.stringify(opt)),
              value: opt,
            }) as Option<Val>,
        )
        .concat(customValues),
    [options, getOptionLabel, getOptionJsxLabel, customValues],
  );
  const computedClassName = cn(className, 'pri-select-v2', {
    'pri-dark': dark,
  });

  const computeSelectedOption = useCallback(
    (currentValue: Val | Val[] | undefined) => {
      if (isMulti) {
        return computedOptions?.filter(opt =>
          (currentValue as Val[])?.find(selected => isEqual(selected, getOptionValue?.(opt.value) || opt.value)),
        );
      }
      return computedOptions?.find(opt => isEqual(getOptionValue?.(opt.value) || opt.value, currentValue));
    },
    [computedOptions, getOptionValue, isMulti],
  );

  return (
    <Flex direction="column" className={computedClassName}>
      <Controller
        control={control}
        name={name}
        render={controllerProps => {
          const {
            field: { value: currentValue, onChange: controllerOnChange, ref, ...restOfControllerProps },
          } = controllerProps;

          const selectedOptions = computeSelectedOption(currentValue);

          return (
            <Flex direction="column">
              {label && (
                <Label className="pri-mb-3" required={required}>
                  {label}
                </Label>
              )}
              <ReactSelect
                {...restOfControllerProps}
                {...reactSelectProps}
                components={{
                  Option: OptionComponent,
                  DropdownIndicator: ChevronBottomIcon,
                  ClearIndicator: ClearIndicatorComponent,
                }}
                blurInputOnSelect
                onChange={opts => {
                  if (Array.isArray(opts)) {
                    if (maxOptions && opts.length > maxOptions) {
                      toast.error(<>{t('errors.max_options', { max: maxOptions })}</>);
                      return;
                    }

                    const valToSet = [...new Set(opts.map(opt => getOptionValue?.(opt.value) || opt.value))];
                    controllerOnChange(valToSet);
                    onChange?.(valToSet.map(opt => opt.value) as Parameters<typeof onChange>[0]);
                    setCustomValues(customValues.filter(opt => opts.find(item => isEqual(item.value, opt.value))));
                  } else {
                    const value = (opts as Option<Val>)?.value;
                    const valToSet = getOptionValue?.(value) || value;
                    controllerOnChange(valToSet);
                    onChange?.(valToSet as Parameters<typeof onChange>[0]);
                  }
                }}
                styles={{
                  menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 9999 }),
                }}
                menuPortalTarget={document.body}
                closeMenuOnScroll={(e: Event) => {
                  const { classList } = e.target as HTMLElement;
                  return !classList.contains('pri-select-v2__menu-list');
                }}
                isClearable
                value={selectedOptions}
                isOptionSelected={(opt: Option<Val>) => {
                  if (Array.isArray(currentValue))
                    return currentValue?.find?.((selected: Option<Val>) => isEqual(selected, opt.value));
                  return isEqual(currentValue, opt.value);
                }}
                isOptionDisabled={(opt: Option<Val>) => !!isOptionDisabled?.(opt.value)}
                isDisabled={disabled}
                ref={ref}
                options={computedOptions}
                onKeyDown={e => handleOnKeyDown(controllerOnChange, e, selectedOptions)}
                hideSelectedOptions={false}
                placeholder={placeholder}
                classNamePrefix="pri-select-v2"
                menuPosition={menuPlacement !== 'auto' ? 'absolute' : 'fixed'}
                controlShouldRenderValue={!isMulti} // Hide native selected values
                noOptionsMessage={() => (allowNewValues ? t('forms.labels.press_enter') : t('forms.labels.no_option'))}
              />
              {error?.message && (
                <Text className="pri-mt-2" size="sm" weight="light" variant="danger">
                  {error.message.toString()}
                </Text>
              )}
              {!error && helper && (
                <Text className="pri-mt-2" size="sm" weight="light" variant="muted">
                  {helper}
                </Text>
              )}
              {isMulti && (
                <SelectedOptionsPills
                  customValues={customValues}
                  setCustomValues={setCustomValues}
                  controllerOnChange={controllerOnChange}
                  selectedOptions={selectedOptions as Option<Val>[]}
                />
              )}
            </Flex>
          );
        }}
      />
    </Flex>
  );
};

export default Select;
