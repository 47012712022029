import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const AppIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13 1H15V3H13V1ZM13 4H15V6H13V4ZM10 1H12V3H10V1ZM10 4H12V6H10V4Z" fill={color} />
    <path
      d="M14 8V11H2V3H8V2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V11C1 11.2652 1.10536 11.5196 1.29289 11.7071C1.48043 11.8946 1.73478 12 2 12H6V14H4V15H12V14H10V12H14C14.2652 12 14.5196 11.8946 14.7071 11.7071C14.8946 11.5196 15 11.2652 15 11V8H14ZM9 14H7V12H9V14Z"
      fill={color}
    />
  </svg>
);

export default AppIcon;
