/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InternalRefetchQueriesInclude, FetchPolicy } from '@apollo/client';
import { useCallback, useEffect, useMemo } from 'react';
import { pick } from 'lodash';

import * as Generated from '~/graphql/schema';
import type {
  ErrorOutput,
  ExtractTData,
  ExtractTVariables,
  GeneratedType,
  SuccessOutput,
  UsableMethodsUnion,
} from './types';

export type QueryOptions<Variables> = {
  runOnInit?: boolean;
  fetchPolicy?: FetchPolicy;
  refetchQueries?: InternalRefetchQueriesInclude;
  awaitRefetchQueries?: boolean;
  variables?: Variables;
};

export type GetResolverVariables<
  TMethodName extends keyof UsableMethodsUnion = keyof UsableMethodsUnion,
  THook extends GeneratedType[TMethodName] = GeneratedType[TMethodName],
> = ExtractTVariables<Parameters<THook>[0]>;

export function useResolver<
  TMethodName extends keyof UsableMethodsUnion = keyof UsableMethodsUnion,
  THook extends GeneratedType[TMethodName] = GeneratedType[TMethodName],
  TData extends ExtractTData<Parameters<THook>[0]> = ExtractTData<Parameters<THook>[0]>,
  TVariable extends ExtractTVariables<Parameters<THook>[0]> = ExtractTVariables<Parameters<THook>[0]>,
>(
  queryName: TMethodName,
  queryOptions?: QueryOptions<TVariable>,
): {
  executeResolver: (
    v?: TVariable,
  ) => Promise<{ result?: SuccessOutput<TData> | null; error?: ErrorOutput<TData> | null }>;
  result?: SuccessOutput<TData>;
  error?: ErrorOutput<TData>;
  loading: boolean;
  called: boolean;
} {
  const useResolverHook = Generated[queryName];

  const serializeData = useCallback((data: TData | undefined | null) => {
    if (!data) {
      return {
        result: null,
        error: null,
      };
    }
    const funcNames = Object.keys(data) as (keyof TData)[];
    return funcNames.reduce(
      (acc, funcName) => {
        const resolverResult = data[funcName] as TData & {
          __typename: string;
          data: unknown;
        };
        acc.result[funcName] = resolverResult?.__typename?.endsWith('Success')
          ? (resolverResult.data as SuccessOutput<TData>)
          : null;
        acc.error[funcName] = resolverResult?.__typename?.endsWith('Error')
          ? (resolverResult as ErrorOutput<TData>)
          : null;
        return acc;
      },
      { result: {}, error: {} } as {
        result: Record<keyof TData, SuccessOutput<TData> | null>;
        error: Record<keyof TData, ErrorOutput<TData> | null>;
      },
    );
  }, []);

  const [resolver, { data, loading, called }] = useResolverHook(
    pick(queryOptions ?? {}, ['fetchPolicy', 'refetchQueries', 'awaitRefetchQueries']) as any,
  );
  const { result, error } = useMemo(() => serializeData(data as TData), [serializeData, data]);

  useEffect(() => {
    if (queryOptions?.runOnInit && !called) {
      resolver({ variables: queryOptions.variables as any });
    }
  }, [resolver, called, queryOptions, queryName]);

  const executeResolver = useCallback(
    async (variables?: TVariable) => {
      const r = await resolver({
        variables: {
          ...queryOptions?.variables,
          ...variables,
        },
      } as any);
      return serializeData(r.data as TData);
    },
    [resolver, serializeData, queryOptions],
  );

  return useMemo(
    () => ({
      executeResolver: executeResolver as (v?: TVariable) => Promise<{
        result?: SuccessOutput<TData> | null;
        error?: ErrorOutput<TData> | null;
      }>,
      result: result as SuccessOutput<TData>,
      error: error as ErrorOutput<TData>,
      loading,
      called,
    }),
    [called, error, executeResolver, loading, result],
  );
}
