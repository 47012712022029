import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const FlagFilledIcon: SvgComponent = ({ className, width = 32, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 30H4V2H28L22.2 11L28 20H6V30Z" fill={color} aria-label="fill-background-color" />
  </svg>
);

export default FlagFilledIcon;
