import type { ComponentType } from 'react';
import React from 'react';
import type { ClearIndicatorProps } from 'react-select';

import { CloseIcon } from '~/components/icons';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ClearIndicatorComponent: ComponentType<ClearIndicatorProps<any>> = props => {
  const {
    innerProps: { ref, ...restInnerProps },
    isMulti,
  } = props;

  if (!isMulti) return null;

  return (
    <div {...restInnerProps} ref={ref}>
      <CloseIcon className="pri-mr-4" />
    </div>
  );
};

export default ClearIndicatorComponent;
