import { ok, refuse } from '@primo/operation-result';
import { useCallback, useEffect, useMemo } from 'react';
import type { AcceptEulaMutation } from '~/graphql/schema';
import { useAcceptEulaMutation } from '~/graphql/schema';
import type { UseQueryOptions } from '~/hooks/queries/types';

type UseAcceptEulaVariables = {
  companyId: string;
};

const useMutationAcceptEula = (queryOptions: UseQueryOptions<UseAcceptEulaVariables> = {}) => {
  const serializeAcceptEulaData = useCallback((data: AcceptEulaMutation | undefined | null) => {
    if (data?.updateCompanySetup?.__typename === 'MutationUpdateCompanySetupSuccess') {
      return ok(data.updateCompanySetup.data);
    }
    if (data?.updateCompanySetup?.__typename === 'MutationUpdateCompanySetupError')
      return refuse(data.updateCompanySetup.reasons);
    return refuse(['Unknown error']);
  }, []);

  const [mutation, { data: acceptEulaData, loading: acceptEulaLoading, called: acceptEulaCalled }] =
    useAcceptEulaMutation({
      refetchQueries: ['GetInitialSetup', 'GetCockpitDataQuery'],
    });

  const acceptEulaResult = useMemo(
    () => serializeAcceptEulaData(acceptEulaData),
    [serializeAcceptEulaData, acceptEulaData],
  );

  const acceptEula = useCallback(
    async (variables: UseAcceptEulaVariables) => {
      const result = await mutation({
        variables,
      });
      return serializeAcceptEulaData(result.data);
    },
    [mutation, serializeAcceptEulaData],
  );

  useEffect(() => {
    if (queryOptions.runOnInit && !acceptEulaCalled) acceptEula({ ...queryOptions });
  }, [acceptEula, queryOptions, acceptEulaCalled]);

  return useMemo(
    () => ({
      acceptEula,
      acceptEulaResult,
      acceptEulaLoading,
      acceptEulaCalled,
    }),
    [acceptEula, acceptEulaResult, acceptEulaLoading, acceptEulaCalled],
  );
};

export default useMutationAcceptEula;
