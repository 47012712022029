import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isRefusal } from '@primo/operation-result';
import { omit } from 'lodash';
import useGetCatalog from './_queries/useGetCatalog';
import type { CompanyCatalog, OrderStepperContextData } from '../types';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { StepperContext } from '~/components/shared/Stepper';
import type { Employee, OrderShippingAddressChoice } from '~/graphql/schema';
import { OrderSource, OrderStatus } from '~/graphql/schema';
import useQueryGetEmployee from '~/hooks/queries/employees/useQueryGetEmployee';
import useMutationCreateOrder from '~/hooks/queries/orders/useMutationCreateOrder';
import useUpdateAddressIfNeeded from './useUpdateAddressIfNeeded';

export type OrderStepperContextProps = {
  employee?: Pick<Employee, 'id' | 'firstName' | 'lastName' | 'address'>;
  loading: boolean;
  setupCalled: boolean;
  catalog: CompanyCatalog[];
  contextData: OrderStepperContextData;
  setContextData: (contextData: OrderStepperContextData) => void;
  onFinish: (contextData?: OrderStepperContextData) => Promise<void>;
};

export const OrderStepperContext = createContext<OrderStepperContextProps>({
  loading: false,
  setupCalled: false,
  catalog: [],
  contextData: {},
  setContextData: () => {},
  onFinish: async () => {},
});

export const OrderStepperProvider: FunctionComponent<{ employeeId?: string | null }> = ({ children, employeeId }) => {
  const { t } = useTranslation();
  const { company, employee: loggedUser } = useContext(PrimoAdminContext);
  const [employee, setEmployee] = useState<OrderStepperContextProps['employee']>();
  const { toggleLoading, onClose } = useContext(StepperContext);
  const { catalog, catalogLoading, catalogCalled } = useGetCatalog();
  const [contextData, setContextData] = useState<OrderStepperContextData>({});
  const updateAddressIfNeeded = useUpdateAddressIfNeeded();

  const { createOrder } = useMutationCreateOrder();
  const { getEmployee } = useQueryGetEmployee();

  const onFinish = useCallback(
    async (finishContextData?: OrderStepperContextData) => {
      const data = finishContextData || contextData;
      if (!company || !loggedUser || !data.address || !data.equipments) return;
      toggleLoading();
      const shippingAddressType = data.address.choice.split('-')[0].toUpperCase() as OrderShippingAddressChoice;

      const createOrderResult = await createOrder({
        companyId: company.id,
        productsInOrder: data.equipments.map(equipment => ({
          productId: equipment.item.id,
          quantity: equipment.quantity,
        })),
        data: {
          shippingAddressType,
          initiatorId: loggedUser.id,
          companyId: company.id,
          employeeId,
          status: OrderStatus.Placed,
          source: OrderSource.Cockpit,
          shippingAddress: omit(data.address.location, '__typename'),
          companyComment: data.companyComment || undefined,
          billingProfileId: data.billingProfileId,
        },
      });

      if (isRefusal(createOrderResult)) {
        toast.error(<>{t('errors.failed_to_create_order')}</>);
      }

      await updateAddressIfNeeded(shippingAddressType, omit(data.address.location, '__typename'), employeeId);

      toggleLoading();
      onClose?.();
      toast.success(<>{t('steppers.orders.order_success')}</>);
    },
    [company, contextData, createOrder, employeeId, loggedUser, onClose, t, toggleLoading, updateAddressIfNeeded],
  );

  useEffect(() => {
    const init = async () => {
      if (!employeeId) return;
      const fetchedEmployeeResult = await getEmployee({ employeeId });
      if (isRefusal(fetchedEmployeeResult)) return;
      setEmployee(fetchedEmployeeResult.data || undefined);
    };

    init();
  }, [employeeId, getEmployee, setEmployee]);

  const loading = catalogLoading;
  const setupCalled = catalogCalled;

  const value = useMemo(
    () => ({
      catalog,
      loading,
      setupCalled,
      contextData,
      setContextData,
      onFinish,
      employee,
    }),
    [catalog, loading, setupCalled, contextData, setContextData, onFinish, employee],
  );

  return <OrderStepperContext.Provider value={value}>{children}</OrderStepperContext.Provider>;
};
