import moment from 'moment';
import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import EmployeeStatusPill from '~/components/featured/pills/EmployeeStatusPill';
import UserIcon from '~/components/icons/user';
import CardTile from '~/components/shared/CardTile';
import Loader from '~/components/shared/Loader';
import Message from '~/components/shared/Message';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { useFormat } from '~/components/shared/formatDate/Date';
import { Form } from '~/components/shared/forms/Form';
import Radio from '~/components/shared/forms/Radio';
import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { Truthy } from '~/utils/truthy';
import { OnboardingStepperContext } from '../context';
import type { OnboardingStepperContextData } from '../types';

const ChooseEmployeeStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const dateFormat = useFormat('date');
  const { pendingOnboardings, setupCalled, loading, setContextData, contextData } =
    useContext(OnboardingStepperContext);
  const { setFooterProps } = useContext(StepperContext);
  const { company } = useContext(PrimoAdminContext);
  const form = useForm({ defaultValues: { employeeChoice: 'new', employee: '' } });
  const { watch, setValue } = form;
  const employeeChoice = watch('employeeChoice');
  const employee = watch('employee');

  const onNext = useCallback(() => {
    const newContextData: OnboardingStepperContextData = { ...contextData, employee: {} };
    const selectedEmployee = pendingOnboardings.find(po =>
      employeeChoice !== 'new' ? po.employeeId === employeeChoice : po.id === employee,
    );
    if (employeeChoice !== 'new' && selectedEmployee) {
      newContextData.employee = {
        ...selectedEmployee,
        startDate: moment().utc(selectedEmployee.startDate).format(dateFormat),
        automaticEnrollment: Boolean(company?.hasMDM),
      };
    }
    setContextData(newContextData);
    return true;
  }, [contextData, pendingOnboardings, employeeChoice, setContextData, employee, dateFormat, company?.hasMDM]);

  useEffect(() => {
    setFooterProps({ onNext });
  }, [setFooterProps, onNext]);

  useEffect(() => {
    if (setupCalled && !loading && employee === '' && pendingOnboardings.length > 0) {
      setValue('employee', pendingOnboardings[0].id);
      setValue('employeeChoice', 'new');
    }
  }, [setupCalled, loading, employee, pendingOnboardings, setValue]);

  return (
    <Step size="lg">
      <Title className="pri-mb-9" level={2}>
        {t('steppers.onboarding.onboard_the_employee')}
      </Title>
      <Form form={form}>
        {loading ? (
          <Loader spacing="md" />
        ) : (
          <Flex direction="column" gap={6}>
            <Text className="pri-mb-3" size="lg" weight="medium">
              {t('steppers.onboarding.who_is_it_for')}
            </Text>
            <Radio
              name="employeeChoice"
              control={form.control}
              options={[
                {
                  label: (
                    <Text weight="light" size="lg" className="">
                      {t('forms.labels.new_employee')}
                    </Text>
                  ),
                  value: 'new',
                },
                {
                  separator: (
                    <Text weight="light" size="lg" className="pri-mt-4">
                      {t('steppers.onboarding.onboarding_already_in_progress')}
                    </Text>
                  ),
                },
                ...(pendingOnboardings.length > 0
                  ? pendingOnboardings.map(po => ({
                      label: (
                        <CardTile
                          spacing="md"
                          prefixIcon={<UserIcon />}
                          title={po.fullName}
                          statusPill={<EmployeeStatusPill startDate={po.startDate} onboardingStatus={po.status} />}
                        />
                      ),
                      value: po.employeeId,
                      dotAlignment: 'center' as const,
                    }))
                  : [
                      {
                        separator: (
                          <Flex className="pri-mt--4" direction="column" fullWidth>
                            <Message
                              variant="plain-info"
                              text={
                                !company?.isHrisSyncEnabled
                                  ? t('forms.labels.enable_hris_to_simplify_onboardings')
                                  : t('forms.labels.no_onboarding_in_progress')
                              }
                            />
                          </Flex>
                        ),
                      },
                    ]),
              ].filter(Truthy)}
            />
          </Flex>
        )}
      </Form>
    </Step>
  );
};

export default ChooseEmployeeStep;
