import cn from 'classnames';
import type { ReactNode } from 'react';
import React, { useMemo } from 'react';

import Tooltip from '~/components/shared/Tooltip';
import './index.scss';

type SharedProps = {
  variant?: 'dark' | 'light' | 'primary' | 'muted' | 'muted-light' | 'danger' | 'success' | 'light-muted';
  size?: 'lg' | 'md' | 'sm';
  weight?: 'light' | 'regular' | 'medium';
  align?: 'left' | 'center' | 'right';
  onClick?: () => void;
  italic?: true;
  letterSpacing?: 2 | 4 | 8;
  noWrap?: boolean;
  noTooltip?: boolean;
};

type TextPropsGeneric = SharedProps & {
  children?: ReactNode | ReactNode[];
  maxLength?: never;
};

type TextPropsMaxLength = SharedProps & {
  maxLength?: number;
  children: string;
};

export type TextProps = TextPropsGeneric | TextPropsMaxLength;

export const Text: FunctionComponent<TextProps> = ({
  className,
  variant,
  children,
  size,
  weight,
  align,
  letterSpacing,
  italic,
  noWrap,
  noTooltip,
  ...rest
}) => {
  const classNames = cn(className, 'pri-text', {
    [`pri-text-variant-${variant}`]: variant,
    [`pri-text-${size}`]: size,
    [`pri-weight-${weight}`]: weight,
    [`pri-text-align-${align}`]: align,
    [`pri-text-letter-spacing-${letterSpacing}`]: letterSpacing,
    'pri-text-italic': italic,
    'pri-text-no-wrap': noWrap,
  });
  const hasMaxLength = 'maxLength' in rest && typeof children === 'string';
  const text = useMemo(() => {
    if (!hasMaxLength) return children;
    if (hasMaxLength && noTooltip)
      return <span className={classNames}>{`${children.slice(0, rest.maxLength)}...`}</span>;
    return children.length > (rest.maxLength || 0) ? (
      <Tooltip label={children}>
        <span className={classNames}>{`${children.slice(0, rest.maxLength)}...`}</span>
      </Tooltip>
    ) : (
      children
    );
  }, [hasMaxLength, children, rest.maxLength, noTooltip, classNames]);
  return <span className={classNames}>{text}</span>;
};
