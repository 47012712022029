// extracted by mini-css-extract-plugin
var _1 = "#000";
var _2 = "#f2fbfd";
var _3 = "#e0f6fb";
var _4 = "#bdedf6";
var _5 = "#99edff";
var _6 = "rgba(0,0,0,0)";
var _7 = "#68e4ff";
var _8 = "#66d5ea";
var _9 = "#1db6d3";
var _a = "#1897af";
var _b = "#0d515e";
var _c = "#fcf8f4";
var _d = "#fcf8f4";
var _e = "#ded9d0";
var _f = "#d5c7b9";
var _10 = "#d9ccbf";
var _11 = "#fffdfa";
var _12 = "#c6b39f";
var _13 = "#b8a48e";
var _14 = "#a98f75";
var _15 = "#90765a";
var _16 = "#78624c";
var _17 = "rgba(0,0,0,0)";
var _18 = "#ffd7d9";
var _19 = "rgba(0,0,0,0)";
var _1a = "rgba(0,0,0,0)";
var _1b = "rgba(0,0,0,0)";
var _1c = "rgba(0,0,0,0)";
var _1d = "#da1e28";
var _1e = "rgba(0,0,0,0)";
var _1f = "#a2191f";
var _20 = "rgba(0,0,0,0)";
var _21 = "#f6f6f6";
var _22 = "#f1efef";
var _23 = "#c6c6c6";
var _24 = "#8c8c8c";
var _25 = "rgba(0,0,0,0)";
var _26 = "#787878";
var _27 = "#484848";
var _28 = "#1e0918";
var _29 = "rgba(0,0,0,0)";
var _2a = "rgba(0,0,0,0)";
var _2b = "rgba(0,0,0,0)";
var _2c = "#a7f0ba";
var _2d = "rgba(0,0,0,0)";
var _2e = "rgba(0,0,0,0)";
var _2f = "rgba(0,0,0,0)";
var _30 = "rgba(0,0,0,0)";
var _31 = "#01b92c";
var _32 = "rgba(0,0,0,0)";
var _33 = "#0e6027";
var _34 = "rgba(0,0,0,0)";
var _35 = "#fff7da";
var _36 = "rgba(0,0,0,0)";
var _37 = "#f3d000";
var _38 = "rgba(0,0,0,0)";
var _39 = "rgba(0,0,0,0)";
var _3a = "rgba(0,0,0,0)";
var _3b = "rgba(0,0,0,0)";
var _3c = "#b54708";
var _3d = "rgba(0,0,0,0)";
var _3e = "rgba(0,0,0,0)";
var _3f = "#fff";
var _40 = "rgba(0,0,0,0)";
var _41 = "#fdffa6";
var _42 = "rgba(0,0,0,0)";
var _43 = "rgba(0,0,0,0)";
var _44 = "rgba(0,0,0,0)";
var _45 = "rgba(0,0,0,0)";
var _46 = "rgba(0,0,0,0)";
var _47 = "rgba(0,0,0,0)";
var _48 = "rgba(0,0,0,0)";
var _49 = "rgba(0,0,0,0)";
export { _1 as "colorBlack", _2 as "colorBrand100", _3 as "colorBrand200", _4 as "colorBrand300", _5 as "colorBrand400", _6 as "colorBrand50", _7 as "colorBrand500", _8 as "colorBrand600", _9 as "colorBrand700", _a as "colorBrand800", _b as "colorBrand900", _c as "colorCream100", _d as "colorCream150", _e as "colorCream200", _f as "colorCream300", _10 as "colorCream400", _11 as "colorCream50", _12 as "colorCream500", _13 as "colorCream600", _14 as "colorCream700", _15 as "colorCream800", _16 as "colorCream900", _17 as "colorError100", _18 as "colorError200", _19 as "colorError300", _1a as "colorError400", _1b as "colorError50", _1c as "colorError500", _1d as "colorError600", _1e as "colorError700", _1f as "colorError800", _20 as "colorError900", _21 as "colorGrey100", _22 as "colorGrey200", _23 as "colorGrey300", _24 as "colorGrey400", _25 as "colorGrey50", _26 as "colorGrey500", _27 as "colorGrey600", _28 as "colorGrey700", _29 as "colorGrey800", _2a as "colorGrey900", _2b as "colorSuccess100", _2c as "colorSuccess200", _2d as "colorSuccess300", _2e as "colorSuccess400", _2f as "colorSuccess50", _30 as "colorSuccess500", _31 as "colorSuccess600", _32 as "colorSuccess700", _33 as "colorSuccess800", _34 as "colorSuccess900", _35 as "colorWarning100", _36 as "colorWarning200", _37 as "colorWarning300", _38 as "colorWarning400", _39 as "colorWarning50", _3a as "colorWarning500", _3b as "colorWarning600", _3c as "colorWarning700", _3d as "colorWarning800", _3e as "colorWarning900", _3f as "colorWhite", _40 as "colorYellow100", _41 as "colorYellow200", _42 as "colorYellow300", _43 as "colorYellow400", _44 as "colorYellow50", _45 as "colorYellow500", _46 as "colorYellow600", _47 as "colorYellow700", _48 as "colorYellow800", _49 as "colorYellow900" }
