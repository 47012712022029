import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const Logo3PlanesUnformedIcon: SvgComponent = ({ className, width = 260, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 262 269"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M119 98L260.993 98L259.504 98.8645L261.014 98.0017L190.018 220.666L190.019 138.9L119 98Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 146L153.993 146L152.504 146.864L154.014 146.002L83.0178 268.666L83.0186 186.9L12 146Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1.15644e-05L141.993 0L140.504 0.86447L142.014 0.0017378L71.0178 122.666L71.0186 40.8996L0 1.15644e-05Z"
      fill={color}
    />
  </svg>
);

export default Logo3PlanesUnformedIcon;
