import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const DatabaseIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 3H8C7.46957 3 6.96086 3.21071 6.58579 3.58579C6.21071 3.96086 6 4.46957 6 5V27C6 27.5304 6.21071 28.0391 6.58579 28.4142C6.96086 28.7893 7.46957 29 8 29H24C24.5304 29 25.0391 28.7893 25.4142 28.4142C25.7893 28.0391 26 27.5304 26 27V5C26 4.46957 25.7893 3.96086 25.4142 3.58579C25.0391 3.21071 24.5304 3 24 3ZM24 5V11H8V5H24ZM8 19V13H24V19H8ZM8 27V21H24V27H8Z"
      fill={color}
    />
    <path
      d="M11 9C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7C10.4477 7 10 7.44772 10 8C10 8.55228 10.4477 9 11 9Z"
      fill={color}
    />
    <path
      d="M11 17C11.5523 17 12 16.5523 12 16C12 15.4477 11.5523 15 11 15C10.4477 15 10 15.4477 10 16C10 16.5523 10.4477 17 11 17Z"
      fill={color}
    />
    <path
      d="M11 25C11.5523 25 12 24.5523 12 24C12 23.4477 11.5523 23 11 23C10.4477 23 10 23.4477 10 24C10 24.5523 10.4477 25 11 25Z"
      fill={color}
    />
  </svg>
);

export default DatabaseIcon;
