import React, { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';

import { Form } from '~/components/shared/forms/Form';
import Select from '~/components/shared/forms/Select';
import Bloc from '~/components/shared/shaping/Bloc';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';
import { AdminNavSectionContext } from '../context';

const SelectCompanyPart: FunctionComponent = () => {
  const { companies } = useContext(AdminNavSectionContext);
  const { employee, setImpersonateCompanyId } = useContext(PrimoAdminContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [companyIdFromStorage, setCompanyIdInStorage] = useLocalStorage('impersonatedCompanyId');
  const employeeIsAdmin = Boolean(employee?.isAdmin);

  const defaultSelectedCompany = useMemo(() => {
    const companyIdFromParams = searchParams.get('companyId');
    const requestedCompanyId = companyIdFromParams || companyIdFromStorage;
    const companyIdToUse = employeeIsAdmin ? requestedCompanyId : employee?.companyId;
    return companies.find(company => company.id === companyIdToUse);
  }, [searchParams, companyIdFromStorage, employeeIsAdmin, employee?.companyId, companies]);

  const form = useForm({
    defaultValues: {
      company: defaultSelectedCompany?.id,
    },
  });
  const { watch } = form;
  const selectedCompany = watch('company');

  useEffect(() => {
    if (!selectedCompany) return;
    setTimeout(() => {
      setSearchParams(
        previousParams => {
          const params = new URLSearchParams(previousParams);
          if (employeeIsAdmin) {
            params.set('companyId', selectedCompany);
          } else {
            params.delete('companyId');
          }
          return params;
        },
        { replace: true },
      );
    }, 0); // This is a trick to make it pushed to the end of the event loop, so after the others setSearchParams
    setImpersonateCompanyId(selectedCompany);
  }, [employeeIsAdmin, selectedCompany, setCompanyIdInStorage, setImpersonateCompanyId, setSearchParams]);

  if (!employee?.companyId) return null;

  return (
    <Form form={form}>
      <Bloc style={{ width: 200 }}>
        <Select
          name="company"
          form={form}
          options={companies}
          getOptionLabel={company => company.name}
          getOptionValue={company => company.id}
          onChange={company => {
            if (!company) return;
            const { id: companyId } = company;
            setCompanyIdInStorage(companyId);
          }}
        />
      </Bloc>
    </Form>
  );
};

export default SelectCompanyPart;
