import { isEqual } from 'lodash';
import React from 'react';
import Flex from '~/components/shared/shaping/Flex';

import DeleteIcon from '~/components/icons/delete';
import Button from '~/components/shared/buttons/Button';
import Pill from '~/components/shared/Pill';
import type { Option, Value } from '../types';

type SelectedOptionsProps<Val extends Value> = {
  customValues: Option<Val>[];
  setCustomValues: (vals: Option<Val>[]) => void;
  controllerOnChange: (vals: Val[]) => void;
  selectedOptions: Option<Val>[];
};

const SelectedOptionsPills = <Val extends Value>({
  customValues,
  setCustomValues,
  controllerOnChange,
  selectedOptions,
}: SelectedOptionsProps<Val>) => (
  <Flex direction="row" gap={3} wrap="wrap" className="pri-mt-6 pri-select__multi-selected-options">
    {selectedOptions.map(option => (
      <Pill key={JSON.stringify(option.label)} color="grey">
        <Flex align="center" gap={2}>
          {option.label}
          <Button
            variant="no-style"
            onClick={() => {
              controllerOnChange(selectedOptions.filter(v => !isEqual(v, option)).map(v => v.value));
              setCustomValues(customValues.filter(opt => !isEqual(opt.value, option.value)));
            }}
          >
            <DeleteIcon width={16} />
          </Button>
        </Flex>
      </Pill>
    ))}
  </Flex>
);

export default SelectedOptionsPills;
