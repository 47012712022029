import { isRefusal } from '@primo/operation-result';
import moment from 'moment';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { StepperContext } from '~/components/shared/Stepper';
import useUpdateAddressIfNeeded from '~/features/steppers/OnboardingStepper/context/useUpdateAddressIfNeeded';
import type { CompanyExternalIntegration, OrderShippingAddressChoice } from '~/graphql/schema';
import { EmployeeOffboardingStatus } from '~/graphql/schema';
import useMutationOffboardEmployee from '~/hooks/queries/employees/useMutationOffboardEmployee';
import useQueryGetOffboardings from '~/hooks/queries/offboardings/useQueryGetOffboardings';
import { PrimoAdminContext } from '~/providers/primo/PrimoAdminProvider';

import { useResolver } from '~/hooks/queries/useResolver';
import type { CompanyPendingOffboarding, OffboardingStepperContextData } from '../types';

export type OffboardingStepperContextProps = {
  loading: boolean;
  setupCalled: boolean;
  pendingOffboardings: CompanyPendingOffboarding[];
  contextData: OffboardingStepperContextData;
  companyExternalIntegrations: Array<CompanyExternalIntegration>;
  companyExternalIntegrationsLoading: boolean;
  setContextData: (contextData: OffboardingStepperContextData) => void;
  onFinish: (contextData?: OffboardingStepperContextData) => Promise<void>;
};

export const OffboardingStepperContext = createContext<OffboardingStepperContextProps>({
  loading: false,
  setupCalled: false,
  pendingOffboardings: [],
  contextData: {},
  companyExternalIntegrations: [],
  companyExternalIntegrationsLoading: false,
  setContextData: () => {},
  onFinish: async () => {},
});

export const OffboardingStepperProvider: FunctionComponent<{
  companyId: string;
  employeeId?: string | null;
}> = ({ children, employeeId, companyId }) => {
  const { t } = useTranslation();
  const { employee: loggedUser } = useContext(PrimoAdminContext);
  const { toggleLoading, onClose, setCurrentStep } = useContext(StepperContext);
  const getEmployeeOptions = useMemo(() => ({ fetchPolicy: 'no-cache' as const }), []);
  const { executeResolver: getEmployee, called: employeeCalled } = useResolver(
    'useGetOffboardingEmployeeLazyQuery',
    getEmployeeOptions,
  );
  const [contextData, setContextData] = useState<OffboardingStepperContextData>({});
  const updateAddressIfNeeded = useUpdateAddressIfNeeded();

  const getCompanyExternalIntegrationsOptions = useMemo(
    () => ({ fetchPolicy: 'no-cache' as const, runOnInit: true, variables: { companyId, enabledOnly: true } }),
    [companyId],
  );
  const { result: companyExternalIntegrationsResult, loading: companyExternalIntegrationsLoading } = useResolver(
    'useGetCompanyExternalIntegrationsLazyQuery',
    getCompanyExternalIntegrationsOptions,
  );

  const { offboardEmployee } = useMutationOffboardEmployee();
  const {
    offboardingsResult,
    offboardingsLoading: pendingOffboardingsLoading,
    offboardingsCalled: pendingOffboardingsCalled,
  } = useQueryGetOffboardings({ runOnInit: true, companyId, status: EmployeeOffboardingStatus.Pending });

  const pendingOffboardings = useMemo(
    () => (isRefusal(offboardingsResult) ? [] : offboardingsResult.data.list),
    [offboardingsResult],
  );
  const loading = pendingOffboardingsLoading;
  const setupCalled = pendingOffboardingsCalled && (!employeeId || employeeCalled);

  useEffect(() => {
    if (employeeId && !employeeCalled) {
      const asyncEffect = async () => {
        const employeeResult = await getEmployee({ companyId, employeeId });
        if (
          !employeeResult?.error ||
          employeeResult.error.getDevices ||
          employeeResult.error.getEmployee ||
          !employeeResult?.result?.getEmployee
        ) {
          toast.error(<>{t('errors.unknown_error')}</>);
          return;
        }

        setContextData({
          employee: { ...employeeResult.result.getEmployee, devices: employeeResult.result?.getDevices?.list ?? [] },
        });
      };
      asyncEffect();
    }
  }, [employeeCalled, companyId, employeeId, getEmployee, setCurrentStep, t]);

  const onFinish = useCallback(
    async (finishContextData?: OffboardingStepperContextData) => {
      const { employee, devices, address, apps, procedureDate, procedureTime, scheduling } =
        finishContextData || contextData;
      if (!loggedUser || !employee) return;
      toggleLoading();
      const shippingAddressType = address?.choice.split('-')[0].toUpperCase() as OrderShippingAddressChoice;

      const procedureTimeHour = moment(procedureTime ?? '09:00', 'HH:mm').get('hour');
      const procedureTimeMinute = moment(procedureTime ?? '09:00', 'HH:mm').get('minute');
      const procedureDateUTC = moment() // get the current day with timezone
        .startOf('day') // set the time to 00:00 (corresponding to the timezone)
        .set('date', moment(procedureDate).get('date')) // set the day from the selected date (corresponding to the timezone)
        .add(procedureTimeHour, 'hour') // set the hour from the selected time
        .add(procedureTimeMinute, 'minute') // set the minute from the selected time
        .toISOString(); // convert to UTC string

      const result = await offboardEmployee({
        employee: {
          phone: employee.phone || undefined,
          personalEmail: employee.personalEmail || undefined,
        },
        employeeId: employee.id,
        apps: apps || [],
        procedureDate: scheduling === 'NOW' ? moment.utc().startOf('day').toISOString() : procedureDateUTC,
        devices: (devices || []).map(device => ({
          deviceId: device.id,
          data: device.handleDeviceData,
          deviceName: device.name || undefined,
          recycle: device.handleDeviceRecycle,
        })),
      });

      if (isRefusal(result)) {
        toggleLoading();
        toast.error(<>{t('errors.unknown_error')}</>);
        return;
      }

      if (address) {
        await updateAddressIfNeeded(shippingAddressType, address.location);
      }

      toggleLoading();
      onClose();
    },
    [contextData, loggedUser, offboardEmployee, onClose, t, toggleLoading, updateAddressIfNeeded],
  );

  const value = useMemo(
    () => ({
      loading,
      setupCalled,
      pendingOffboardings,
      contextData,
      companyExternalIntegrations: companyExternalIntegrationsResult?.getCompanyExternalIntegrations || [],
      companyExternalIntegrationsLoading,
      setContextData,
      onFinish,
    }),
    [
      loading,
      setupCalled,
      pendingOffboardings,
      contextData,
      companyExternalIntegrationsResult,
      companyExternalIntegrationsLoading,
      setContextData,
      onFinish,
    ],
  );

  return <OffboardingStepperContext.Provider value={value}>{children}</OffboardingStepperContext.Provider>;
};
