import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const CloseIcon: SvgComponent = ({ className, width = 16, color = theme.colorGrey700 }) => (
  <svg className={className} width={width} height={width} viewBox="0 0 16 17" fill="none">
    <path
      d="M12 5.2L11.3 4.5L8 7.8L4.7 4.5L4 5.2L7.3 8.5L4 11.8L4.7 12.5L8 9.2L11.3 12.5L12 11.8L8.7 8.5L12 5.2Z"
      fill={color}
    />
  </svg>
);

export default CloseIcon;
