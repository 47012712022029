import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Logo3PlanesUnformedIcon from '~/components/icons/logo3planesUnformed';
import LogoFleet from '~/components/icons/logoFleet';
import LogoTypo from '~/components/icons/logoTypo';
import Flex from '~/components/shared/shaping/Flex';

import * as theme from '~/assets/scss/theme.module.scss';
import { SignupProvider } from './context';
import './index.scss';
import SignUpFlowPart from './part/SignupFlowPart';

const SignUpPage: FunctionComponent = () => (
  <SignupProvider>
    <Flex justify="center" align="center" className="signup-page">
      <div id="signup-container-wrapper">
        <Logo3PlanesUnformedIcon className="rightFleet" color={theme.colorBrand300} />
        <div id="signup-container">
          <Flex direction="column">
            <Flex justify="center" className="pri-mb-11">
              <LogoFleet color={theme.colorBrand500} />
              <LogoTypo color="black" />
            </Flex>
            <Routes>
              <Route index element={<SignUpFlowPart />} />
            </Routes>
          </Flex>
        </div>
        <Logo3PlanesUnformedIcon className="leftFleet" color={theme.colorBrand300} />
      </div>
    </Flex>
  </SignupProvider>
);
export default SignUpPage;
