import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const InfoIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.8"
      d="M8.5 11V6.5H6.5V7.5H7.5V11H6V12H10V11H8.5ZM8 3.5C7.6 3.5 7.2 3.8 7.2 4.3C7.2 4.8 7.6 5 8 5C8.4 5 8.8 4.7 8.8 4.2C8.8 3.7 8.4 3.5 8 3.5Z"
      fill={color}
    />
    <path
      opacity="0.8"
      d="M8 15C4.1 15 1 11.9 1 8C1 4.1 4.1 1 8 1C11.9 1 15 4.1 15 8C15 11.9 11.9 15 8 15ZM8 2C4.7 2 2 4.7 2 8C2 11.3 4.7 14 8 14C11.3 14 14 11.3 14 8C14 4.7 11.3 2 8 2Z"
      fill={color}
    />
  </svg>
);

export default InfoIcon;
