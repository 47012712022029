import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const FlagIcon: SvgComponent = ({ className, width = 32, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.5 22.5H3V1.5H21L16.65 8.25L21 15H4.5V22.5ZM4.5 13.5H18.2475L14.85 8.25L18.2475 3H4.5V13.5Z"
      fill={color}
    />
  </svg>
);

export default FlagIcon;
