import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const StarEmptyIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 6.52L18.76 12.1L19.22 13.1L20.22 13.25L26.38 14.14L22 18.44L21.25 19.17L21.43 20.17L22.48 26.3L16.97 23.41L16 23L15.07 23.49L9.56003 26.34L10.56 20.21L10.74 19.21L10 18.44L5.58003 14.09L11.74 13.2L12.74 13.05L13.2 12.05L16 6.52ZM16 2L11.45 11.22L1.28003 12.69L8.64003 19.87L6.90003 30L16 25.22L25.1 30L23.36 19.87L30.72 12.7L20.55 11.22L16 2Z"
      fill={color}
    />
  </svg>
);

export default StarEmptyIcon;
