import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const ChevronLeftIcon: SvgComponent = ({ className, width = 16, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 8L10 3L10.7 3.7L6.4 8L10.7 12.3L10 13L5 8Z" fill={color} />
  </svg>
);

export default ChevronLeftIcon;
