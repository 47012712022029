import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const ShieldIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 7.05V1H10.5V2H12V3H10.5V4H12V7.05C11.15 7.25 10.5 8.05 10.5 9C10.5 10.1 11.4 11 12.5 11C13.6 11 14.5 10.1 14.5 9C14.5 8.05 13.85 7.3 13 7.05ZM12.5 10C11.95 10 11.5 9.55 11.5 9C11.5 8.45 11.95 8 12.5 8C13.05 8 13.5 8.45 13.5 9C13.5 9.55 13.05 10 12.5 10Z"
      fill={color}
    />
    <path
      d="M7.5 15.5L4.75 13.9C3.05 12.9 2 11.1 2 9.15V2C2 1.45 2.45 1 3 1H9V2H3V9.15C3 10.75 3.85 12.25 5.25 13.05L7.5 14.4L9.75 13.05C10.3 12.7 10.8 12.3 11.15 11.75L11.95 12.3C11.5 12.95 10.9 13.5 10.2 13.9L7.5 15.5Z"
      fill={color}
    />
  </svg>
);

export default ShieldIcon;
