import React from 'react';
import cn from 'classnames';
import type { ReactElement, ReactNode } from 'react';

import Flex from '~/components/shared/shaping/Flex';
import { Text, Title } from '~/components/shared/typography';

type CardExtendedTitleProps = {
  children?: never;
  title: string;
  subtitle: string;
  icon?: ReactElement<SvgComponentProps>;
  helperText?: string | ReactNode;
};

export const CardExtendedTitle: FunctionComponent<CardExtendedTitleProps> = ({
  icon,
  title,
  subtitle,
  className,
  helperText,
}) => (
  <Flex className={cn('pri-card-extended-title', className)} justify="start" align="start" gap={4}>
    {icon && <div className="pri-card-extended-title-icon">{icon}</div>}
    <Flex direction="column" align="start" gap={1}>
      <Title level={5}>{title}</Title>
      <Text variant="muted" weight="light">
        {subtitle}
      </Text>
      {typeof helperText === 'string' ? (
        <Text size="sm" weight="light" variant="muted-light">
          {helperText}
        </Text>
      ) : (
        helperText
      )}
    </Flex>
  </Flex>
);
