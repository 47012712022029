import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const CloudServiceIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 8.5V11H2V3H7V2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V11C1 11.2652 1.10536 11.5196 1.29289 11.7071C1.48043 11.8946 1.73478 12 2 12H6V14H4V15H12V14H10V12H14C14.2652 12 14.5196 11.8946 14.7071 11.7071C14.8946 11.5196 15 11.2652 15 11V8.5H14ZM9 14H7V12H9V14Z"
      fill={color}
    />
    <path
      d="M15 5V4H13.9494C13.885 3.68703 13.7607 3.38944 13.5835 3.12355L14.3285 2.37855L13.6215 1.67155L12.8765 2.41655C12.6106 2.2393 12.313 2.11503 12 2.05055V1H11V2.05055C10.687 2.11502 10.3894 2.23928 10.1236 2.4165L9.37855 1.6715L8.67155 2.3785L9.41655 3.1235C9.2393 3.3894 9.11503 3.68701 9.05055 4H8V5H9.05055C9.11502 5.31297 9.23928 5.61056 9.4165 5.87645L8.6715 6.62145L9.3785 7.32845L10.1235 6.58345C10.3894 6.7607 10.687 6.88497 11 6.94945V8H12V6.94945C12.313 6.88498 12.6106 6.76072 12.8764 6.5835L13.6215 7.3285L14.3285 6.6215L13.5835 5.8765C13.7607 5.6106 13.885 5.31299 13.9494 5H15ZM11.5 6C11.2033 6 10.9133 5.91203 10.6666 5.7472C10.42 5.58238 10.2277 5.34811 10.1142 5.07403C10.0006 4.79994 9.97094 4.49834 10.0288 4.20736C10.0867 3.91639 10.2296 3.64912 10.4393 3.43934C10.6491 3.22956 10.9164 3.0867 11.2074 3.02882C11.4983 2.97094 11.7999 3.00065 12.074 3.11418C12.3481 3.22771 12.5824 3.41997 12.7472 3.66664C12.912 3.91332 13 4.20333 13 4.5C12.9996 4.89769 12.8414 5.27897 12.5602 5.56018C12.279 5.84139 11.8977 5.99956 11.5 6V6Z"
      fill={color}
    />
  </svg>
);

export default CloudServiceIcon;
