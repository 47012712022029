import type { MouseEventHandler } from 'react';
import React from 'react';
import cn from 'classnames';

import Bloc from '../shaping/Bloc';

import './style.scss';

type TileProps = {
  fluid?: true;
  selected?: boolean;
  size?: 'sm' | 'md';
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const Tile: FunctionComponent<TileProps> = ({ className, children, selected, fluid, onClick, size = 'md' }) => (
  <Bloc
    rounded
    className={cn('pri-tile', `size-${size}`, className, {
      'cursor-pointer': onClick,
      '--selected': selected,
      '--fluid': fluid,
    })}
    onClick={onClick}
  >
    {children}
  </Bloc>
);

export default Tile;
