import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const BatteryChargingIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 11H26V10C26 9.46957 25.7893 8.96086 25.4142 8.58579C25.0391 8.21071 24.5304 8 24 8H20V10H24V13H27V19H24V22H19V24H24C24.5304 24 25.0391 23.7893 25.4142 23.4142C25.7893 23.0391 26 22.5304 26 22V21H27C27.5304 21 28.0391 20.7893 28.4142 20.4142C28.7893 20.0391 29 19.5304 29 19V13C29 12.4696 28.7893 11.9609 28.4142 11.5858C28.0391 11.2107 27.5304 11 27 11ZM11 22H6V10H12V8H6C5.46957 8 4.96086 8.21071 4.58579 8.58579C4.21071 8.96086 4 9.46957 4 10V22C4 22.5304 4.21071 23.0391 4.58579 23.4142C4.96086 23.7893 5.46957 24 6 24H11V22Z"
      fill={color}
    />
    <path d="M14.81 23.58L13.19 22.42L17.06 17H9.37L16.22 8.38L17.78 9.62L13.51 15H20.94L14.81 23.58Z" fill={color} />
  </svg>
);

export default BatteryChargingIcon;
