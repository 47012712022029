import { zodResolver } from '@hookform/resolvers/zod';
import React, { useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Form } from '~/components/shared/forms/Form';
import Input from '~/components/shared/forms/Input';
import Select from '~/components/shared/forms/Select';
import Loader from '~/components/shared/Loader';
import Flex from '~/components/shared/shaping/Flex';
import { Step } from '~/components/shared/Stepper';
import { StepperContext } from '~/components/shared/Stepper/_components/StepperContext';
import { Title } from '~/components/shared/typography';
import { DevicePlatform, DeviceType } from '~/graphql/schema';
import { PrimoEmployeeContext } from '~/providers/primo/PrimoEmployeeProvider';
import { EmployeeEnrollmentStepperContext } from '../../context';
import type { Fields } from './schema';
import { schema } from './schema';

const UpdateDeviceStep: FunctionComponent = () => {
  const { t } = useTranslation();
  const { employee, company } = useContext(PrimoEmployeeContext);
  const { contextData, loading, setContextData, updateDeviceForEnrollment, setPersistUpdateState } = useContext(
    EmployeeEnrollmentStepperContext,
  );
  const { setFooterProps } = useContext(StepperContext);
  const { selectedDevice } = contextData;

  const form = useForm<Fields>({
    defaultValues: {
      name: selectedDevice?.name ?? t(`forms.default_values.device_name_value`, { arg: employee?.firstName }),
      type: DeviceType.Laptop,
      platform:
        !selectedDevice?.platform || selectedDevice?.platform === DevicePlatform.Unknown
          ? DevicePlatform.Macos
          : selectedDevice?.platform,
    },
    resolver: zodResolver(schema(t)),
  });

  const {
    register,
    trigger,
    getValues,
    formState: { errors },
  } = form;

  const onNext = useCallback(async () => {
    const isValid = await trigger();
    if (!isValid) return false;

    const values = getValues();

    setPersistUpdateState(true);

    const updateSuccess = await updateDeviceForEnrollment({
      name: values.name,
      platform: values.platform,
    });

    if (!updateSuccess) {
      toast.error(`${t('errors.error_when_updating_device')}`);
      return false;
    }

    setContextData({
      ...contextData,
      selectedDevice: { ...selectedDevice, ...values },
    });

    return true;
  }, [
    trigger,
    getValues,
    setContextData,
    updateDeviceForEnrollment,
    contextData,
    t,
    selectedDevice,
    setPersistUpdateState,
  ]);

  useEffect(() => {
    setFooterProps({
      onNext,
      nextLabel: t('buttons.next'),
    });
  }, [setFooterProps, onNext, t]);

  return (
    <Step size="lg">
      {loading ? (
        <Loader spacing="md" />
      ) : (
        <Flex direction="column" gap={7}>
          <Title>{t('titles.enrollment_stepper.update_device.title')}</Title>
          <Flex direction="column" className="pri-mt-4" gap={2}>
            <Form form={form}>
              <Flex direction="column" gap={6}>
                <Flex direction="column" fullWidth>
                  <Input
                    required
                    name="name"
                    label={t('forms.labels.device_name')}
                    register={register}
                    errors={errors}
                  />
                </Flex>
                <Flex direction="row" gap={6} fullWidth>
                  <Select
                    disabled
                    className="pri-flex-1"
                    name="type"
                    label={t('forms.labels.type')}
                    options={[DeviceType.Laptop]}
                    getOptionLabel={type => t(`enums.device_types.${type.toLowerCase()}`)}
                    form={form}
                  />
                  <Select
                    className="pri-flex-1"
                    name="platform"
                    label={t('forms.labels.platform')}
                    options={Object.values(company?.mdmPlatforms ?? [])}
                    getOptionLabel={platform => t(`enums.platform.${platform.toLowerCase()}`)}
                    form={form}
                  />
                </Flex>
              </Flex>
            </Form>
          </Flex>
        </Flex>
      )}
    </Step>
  );
};

export default UpdateDeviceStep;
