import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const DeleteIcon: SvgComponent = ({ className, width = 32, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 9.4L22.6 8L16 14.6L9.4 8L8 9.4L14.6 16L8 22.6L9.4 24L16 17.4L22.6 24L24 22.6L17.4 16L24 9.4Z"
      fill={color}
    />
  </svg>
);

export default DeleteIcon;
