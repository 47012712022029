import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const ContinuousDeployment: SvgComponent = ({ className, width = 16, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 2L18 8L19.4 9.4L23 5.8V19C23 24 19 28 14 28C12.4 28 10.9 27.6 9.7 26.9C10.1 27 10.5 27 11 27C15.4 27 19 23.4 19 19C19 14.6 15.4 11 11 11C6.6 11 3 14.6 3 19C3 25.1 7.9 30 14 30C20.1 30 25 25.1 25 19V5.8L28.6 9.4L30 8L24 2ZM5 19.6C5 19.4 5 19.2 5 19C5 15.7 7.7 13 11 13C14.3 13 17 15.7 17 19C17 22.3 14.3 25 11 25C7.9 25 5.3 22.6 5 19.6Z"
      fill={color}
    />
  </svg>
);

export default ContinuousDeployment;
