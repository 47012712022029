import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const ChevronBottomIcon: SvgComponent = ({ className, width = 10, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 6.00001L0 1.00001L0.7 0.300013L5 4.60001L9.3 0.300013L10 1.00001L5 6.00001Z" fill={color} />
  </svg>
);

export default ChevronBottomIcon;
