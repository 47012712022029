import React from 'react';

import * as theme from '~/assets/scss/theme.module.scss';

const DocumentIcon: SvgComponent = ({ className, width = 24, color = theme.colorGrey700 }) => (
  <svg
    className={className}
    width={width}
    height={width}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.7 9.29999L18.7 2.29999C18.612 2.20227 18.5038 2.12483 18.383 2.07303C18.2621 2.02124 18.1314 1.99631 18 1.99999H8C7.47005 2.00155 6.96224 2.21276 6.58751 2.58749C6.21277 2.96223 6.00156 3.47003 6 3.99999V28C6.00156 28.5299 6.21277 29.0377 6.58751 29.4125C6.96224 29.7872 7.47005 29.9984 8 30H24C24.53 29.9984 25.0378 29.7872 25.4125 29.4125C25.7872 29.0377 25.9984 28.5299 26 28V9.99999C26.0037 9.86854 25.9788 9.73787 25.927 9.61701C25.8752 9.49614 25.7977 9.38798 25.7 9.29999ZM18 4.39999L23.6 9.99999H18V4.39999ZM24 28H8V3.99999H16V9.99999C16.0016 10.5299 16.2128 11.0377 16.5875 11.4125C16.9622 11.7872 17.47 11.9984 18 12H24V28Z"
      fill={color}
    />
  </svg>
);

export default DocumentIcon;
