import { createContext } from 'react';
import type { QueryParams } from '~/providers/search/useQueryParams';

export const DEFAULT_PAGE = 1;

export const DEFAULT_SEARCH = {
  tab: null,
  search: null,
  type: null,
  status: null,
  onboardingStatus: null,
  offboardingStatus: null,
  orderStatus: null,
  shipmentStatus: null,
  enrollmentStatus: null,
  issueStatus: null,
  activityStatus: null,
  encryptionStatus: null,
  osVersionStatus: null,
  edrStatus: null,
  profileId: null,
};

export type SearchContextProps = {
  search: QueryParams;
  page: number;
  setSearch: (search: QueryParams) => void;
  setPage: (page: number) => void;
};

export const SearchContext = createContext<SearchContextProps>({
  search: DEFAULT_SEARCH,
  page: DEFAULT_PAGE,
  setSearch: () => undefined,
  setPage: () => undefined,
});
