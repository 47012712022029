import React from 'react';
import Logo3PlanesUnformedIcon from '~/components/icons/logo3planesUnformed';
import LogoFleet from '~/components/icons/logoFleet';
import LogoTypo from '~/components/icons/logoTypo';
import Flex from '~/components/shared/shaping/Flex';

import * as theme from '~/assets/scss/theme.module.scss';
import './index.scss';

const LoginBackground: FunctionComponent = ({ children, className }) => (
  <Flex justify="center" align="center" className="login-page">
    <div id="login-container-wrapper">
      <Logo3PlanesUnformedIcon className="rightFleet" color={theme.colorBrand300} />
      <div id="login-container" className={className}>
        <Flex direction="column">
          <Flex justify="center" className="pri-mb-6">
            <LogoFleet color={theme.colorBrand500} />
            <LogoTypo color="black" />
          </Flex>
          {children}
        </Flex>
      </div>
      <Logo3PlanesUnformedIcon className="leftFleet" color={theme.colorBrand300} />
    </div>
  </Flex>
);

export default LoginBackground;
